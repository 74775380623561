import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';
import Dropdown from 'react-bootstrap/Dropdown';
import { getAuth, deleteUser } from "firebase/auth";
import Avatar from 'boring-avatars';
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { Typography, AppBar, Toolbar, Box, Button, Grid, SvgIcon, Menu, MenuItem } from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import { ReactComponent as Home } from '../../data/home-icon.svg';
import { ReactComponent as MySessionWhite } from '../../data/mysession-white-icon.svg';
import { ReactComponent as MySessionBlue } from '../../data/mysession-blue-icon.svg';
import { ReactComponent as MyMood } from '../../data/mymood-icon.svg';
import { ReactComponent as Articles } from '../../data/blog-icon.svg';
import { ReactComponent as PokaTherapy } from '../../data/psychology.svg';
import { ReactComponent as FullBlue } from '../../data/fullblue.svg';
import { ReactComponent as IconBlue } from '../../data/iconblue.svg';
import { styled } from '@mui/system';

const CustomMenu = styled(Menu)(() => ({
  '& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
    backgroundColor:"#5477AB",
    gap:"10px",
    borderRadius:"8px",

  }
}));

const NavbarLanding = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const node = useRef();
  const { user, role, tier, premium_status, stripe_customer_id } = useUser();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [email, setEmail] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const isSmallerThan1100px = useMediaQuery({ query: '(max-width: 1100px)' });

  const isMymoodPage = location.pathname === "/mymood";
  const isSessionPage = location.pathname === "/session";
  const isHomePage = location.pathname === "/get-started";
  const isBlogPage = location.pathname === "/blogs";
  const isTherapyPage = location.pathname === "/book-therapist";
  const isTherapist = location.pathname === "/therapist-view";

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Grid mx={isMobile ? 0 : 4}>
      <AppBar position="static" sx={{ bgcolor: "transparent", my: 3, color: "white" }} elevation={0} ref={node}>
        <Toolbar>
          {isMobile ? (
            <Box display={"flex"} height={"50px"} width={"350px"} overflow={"hidden"} alignItems={"center"} >
              <SvgIcon component={IconBlue} inheritViewBox style={{ width: "50px", height: "auto" }} />
              <Typography
                variant="sb24Bold"
                component={RouterLink}
                to="/get-started"
                sx={{ color: "white", textDecoration: 'none', paddingBottom: "5px" }}
              >
                pokamind
              </Typography>
            </Box>
          ) : (
            <>
              <Box display={"flex"} height={"50px"} width={"350px"} overflow={"hidden"} alignItems={"center"} >
                <SvgIcon component={IconBlue} inheritViewBox style={{ width: "50px", height: "auto" }} />
                <Typography
                  variant="sb24Bold"
                  component={RouterLink}
                  to="/get-started"
                  sx={{ color: "white", textDecoration: 'none', paddingBottom: "5px" }}
                >
                  pokamind
                </Typography>
              </Box>
              <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", gap: "15px" }}>
                <Button
                  component={RouterLink}
                  to="/get-started"
                  sx={{
                    m: 0,
                    gap: "8px",
                    "&:hover": {
                      background: "none",
                    }
                  }}
                >
                  <Typography variant="body2SemiBold" color={"white"}>HOME</Typography>
                </Button>
                <Button
                  aria-controls="company-news-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  sx={{
                    m: 0,
                    gap: "8px",
                    color: "primary.backgroundBlue",
                    "&:hover": {
                      color: "primary.backgroundBlue",
                      background: "none"
                    }
                  }}
                >
                  <Typography variant="body2SemiBold" color={"white"}>COMPANY</Typography>
                </Button>
                <CustomMenu
                  id="company-news-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => { navigate('/podcast'); handleMenuClose(); }}>
                    <Typography variant="body2SemiBold" color={"white"}>PODCAST</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => { navigate('/blogs'); handleMenuClose(); }}>
                    <Typography variant="body2SemiBold" color={"white"}>BLOG</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => { navigate('/blogs'); handleMenuClose(); }}>
                    <Typography variant="body2SemiBold" color={"white"}>NEWS</Typography>
                  </MenuItem>
                </CustomMenu>
                <Button
                  component={RouterLink}
                  to="/mymood"
                  sx={{
                    m: 0,
                    gap: "8px",
                    "&:hover": {
                      background: "none",
                    }
                  }}
                >
                  <Typography variant="body2SemiBold" color={"white"}>CAREERS</Typography>
                </Button>
                <Button
                  component={RouterLink}
                  to="/blogs"
                  sx={{
                    m: 0,
                    gap: "8px",
                    "&:hover": {
                      background: "none",
                    }
                  }}
                >
                  <Typography variant="body2SemiBold" color={"white"}>CONTACT US</Typography>
                </Button>
                <Button
                  component={RouterLink}
                  to="/blogs"
                  sx={{
                    boxShadow: "0px 5px 10px #5276AD",
                    borderRadius: "40px",
                    backgroundColor: "white",
                    m: 0,
                    padding: "10px 20px 10px 20px",
                    gap: "8px",
                    "&:hover": {
                      background: "none",
                    }
                  }}
                >
                  <Typography variant="sb16Bolder" color={"#4C6EA2"}>Request a demo</Typography>
                </Button>
              </Grid>
            </>
          )}
          {isMobile ? (
            <Grid container sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} spacing={0}>
              <FontAwesomeIcon
                icon="fa-solid fa-bars"
                size="xl"
                onClick={handleMenuToggle} />
              <CSSTransition
                in={isMenuOpen}
                timeout={300}
                classNames="mobile-menu-transition"
                unmountOnExit
              >
                <div className="mobile_menu">
                  <Grid sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ pr: 3, pb: 3, display: "flex", justifyContent: "end" }}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-xmark"
                        size="xl"
                        style={{ color: '#ffffff' }}
                        onClick={handleMenuToggle} />
                    </Box>
                    <Button
                      to="/get-started"
                      component={RouterLink}
                      sx={{
                        color: "secondary.main",
                        display: "block",
                        my: "5px"
                      }}
                    >
                      <Typography
                        variant="body2SemiBold" color={"white"}
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <div>HOME</div>
                      </Typography>
                    </Button>
                    <Button
                      to="/session"
                      component={RouterLink}
                      sx={{
                        display: "block",
                        color: "white",
                        "&:hover": {
                          color: "white",
                        },
                        my: "5px"
                      }}
                    >
                      <Typography
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <Typography component="div" variant="body2SemiBold" color={"white"} sx={{ color: "secondary.main" }}>COMPANY NEWS</Typography>
                      </Typography>
                    </Button>
                    <Button
                      to="/mymood"
                      component={RouterLink}
                      sx={{
                        color: "secondary.main",
                        display: "block",
                        my: "5px"
                      }}
                    >
                      <Typography
                        variant="body2SemiBold" color={"white"}
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <div>CAREERS</div>
                      </Typography>
                    </Button>
                    <Button
                      to="/book-therapist"
                      component={RouterLink}
                      sx={{
                        color: "secondary.main",
                        display: "block",
                        my: "5px"
                      }}
                    >
                      <Typography
                        variant="body2SemiBold" color={"white"}
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <div>CONTACT US</div>
                      </Typography>
                    </Button>
                    <Button
                      to="/blogs"
                      component={RouterLink}
                      sx={{
                        color: "secondary.main",
                        display: "block",
                        my: "5px"
                      }}
                    >
                      <Typography
                        variant="sb16Bolder"
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <div>Request a demo</div>
                      </Typography>
                    </Button>
                  </Grid>
                </div>
              </CSSTransition>
            </Grid>
          ) : (
            <Grid item className="navbar-dropdown" lg={2} md={1} sm={3} xs={3}>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default NavbarLanding;
