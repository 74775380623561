import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailLink, sendSignInLinkToEmail, GoogleAuthProvider, signInWithPopup, isSignInWithEmailLink, getAdditionalUserInfo } from 'firebase/auth';
import { Form } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from '../contexts/UserContext';
import { auth } from "../Firebase";
import { URL, createNewUserDocument, fetchUserRole, pingBackend } from '../Helpers';
import LoadingSpinner from './shared/LoadingSpinner';
import Logo from '../data/front.svg';
import googleIcon from '../data/icons8-google.svg';
import './style.scss';
import { Grid, Typography, Card, Alert, Button, TextField, Box, SvgIcon } from '@mui/material';
import leftImage from '../data/authImage1.png'
import rightImage from '../data/authImage2.png'
import { ReactComponent as ArrowBack } from '../data/arrow_back.svg';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { user, setRole, role } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  //check if user was going to a different page but got redirected to login first,
  //else send to get-started page
  const from = location.state?.from?.pathname || "/get-started"
  const [emailValid, setEmailValid] = useState(true);
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const isSMobile = useMediaQuery({ query: '(max-width: 600px)' });

  useEffect(() => {
    //if raeched this page while user and role are set, go to next page or getstarted
    if (user && role) {
      console.log('navigating to getstarted from auth', from)
      // pingBackend(user.accessToken);

      navigate(from, { replace: true })
    }
  }, [navigate, user, role]);

  useEffect(() => {
    //check if link is redirection from email validation link
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      setLoading(true)  //set loading to disable any more user input till redirection
      const email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then(async (result) => {
            // console.log(result.user)
            const details = getAdditionalUserInfo(result)
            // console.log(details)
            if (details.isNewUser) {
              //Send request to backend to create new document
              await createNewUserDocument(result.user.accessToken, result.user.email)
            }
            window.localStorage.removeItem('emailForSignIn');
            //this fn gets user role from firestore and set in localstorage
            setRole(await fetchUserRole(result.user))
            //ping backend to reduce cold boot
            // console.log("pinging backend")
            pingBackend(result.user.accessToken);
            navigate(from, { replace: true });
          })
          .catch((error) => {
            alert(error.message);
            setLoading(false)
          });
      }
    }
  }, [navigate, from]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // console.log(result.user)
      const details = getAdditionalUserInfo(result)
      // console.log(details)
      if (details.isNewUser) {
        //Send request to backend to create new document
        await createNewUserDocument(result.user.accessToken, result.user.email)
      }
      // console.log('after signing in: from', from)
      //this fn gets user role from localstorage or firestore
      // and set in localstorage
      setRole(await fetchUserRole(result.user))
      //ping backend to reduce cold boot
      pingBackend(result.user.accessToken);
      navigate(from, { replace: true });
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        // Handle this specific case, e.g., log the event
        console.error('Authentication popup was closed by the user.');
        alert("Authentication popup was closed by the user.");
      } else {
        // Show an alert for other errors
        alert(error.message);
      }
    }
  };

  const actionCodeSettings = {
    url: window.location.hostname === 'localhost' ? 'http://localhost:3000/auth' : 'https://window.pokamind.com/auth',
    handleCodeInApp: true,
  };

  const handleSignIn = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      return;
    }
    setValidated(true);
    setLoading(true);

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setLoading(false);
      setEmailSent(true);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };



  return (
<div className="prevent_overflow">
  <Grid container flexDirection={'column'} justifyContent={'center'} sx={{ minHeight: "100vh", '@media (max-width: 900px)': { marginBottom: "4rem", marginTop: "1rem" } }}>
    <Grid container display={'flex'} justifyContent={'center'}>
      <Grid textAlign={'center'} xs={12} mt={{xs:8, md:0}}>
        <Typography color={"primary.darkerBlue"} variant='h32Bold'>Ready to grow your mind?</Typography>
      </Grid>
      {isMobile ? (
        <>
          <Grid sx={{ '@media (max-width: 500px)': { width: '98%' } }} sm={10} md={4} lg={3} xl={3} mb={5}>
            <Grid mt={5} gap={"32px"} padding={"32px"} border={"1px solid #E3DBD5"} boxShadow={"0px 5px 10px #EFE5DE"} borderRadius={"10px"} bgcolor={"primary.main"} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <Grid>
                <Typography
                  variant="h32Bold"
                  component="a"
                  onClick={() => navigate("/get-started")}
                  sx={{ color: "secondary.mainSand", textDecoration: 'none' }}
                >
                  pokamind
                </Typography>
                <Typography position={'absolute'} variant="body2SemiBold" sx={{ pb: 5, flexGrow: 1, color: "secondary.mainSand" }}>BETA</Typography>
              </Grid>
              {loading ? (
                <LoadingSpinner className="smaller_height" />
              ) : emailSent ? (
                <Alert sx={{ textAlign: 'left', color: 'secondary.mainSand', backgroundColor: "#00ff7c42" }} variant="outlined">
                  <h3><strong>Check your email<br /></strong></h3>A sign in link has been sent to your email address. Please visit the link using this device. If you cannot find the email, be sure to check the spam folder.
                </Alert>
              ) : (
                <>
                  <Button sx={{ backgroundColor: "white", width: "100%", height: "auto", padding: "10px", gap: "12px", color: "primary.darkerBlue", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.12)", '&:hover': { backgroundColor: "#00376b", color: "secondary.mainSand" } }} variant="light" onClick={signInWithGoogle}>
                    <img src={googleIcon} style={{ width: '50px', height: '20px' }} alt='googleIcon' />
                    <Typography variant='sb16Bolder'>Continue with Google</Typography>
                  </Button>
                  <div className='horizontal-line' style={{ height: "1px", backgroundColor: "#5378B0" }}></div>
                  <form style={{ width: "100%" }} noValidate onSubmit={handleSignIn} className="animated-form">
                    <Grid marginBottom={"7%"} gap={"6px"}>
                      <Typography color={'secondary.mainSand'} variant='body2Bold'>Email Address</Typography>
                      <TextField
                        fullWidth
                        id="formBasicEmail"
                        placeholder="email@example.com"
                        type="email"
                        required
                        error={!emailValid}
                        helperText={!emailValid && "Please enter a valid email"}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailValid(e.target.checkValidity());
                        }}
                        InputProps={{ style: { backgroundColor: '#ffffff', height: "45px" } }}
                      />
                    </Grid>
                    <Button
                      fullWidth
                      sx={{ border: "1.5px solid white", padding: "8px", backgroundColor: 'transparent', color: 'secondary.mainSand' }}
                      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#00376b')}
                      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                      type="submit"
                    >
                      <Typography variant='sb16Bolder'>Sign In / Sign Up</Typography>
                    </Button>
                  </form>
                </>
              )}
            </Grid>
            <Grid textAlign={'center'} mt={3}>
              <Typography variant='body1Medium'>By continuing, you are setting up a PokaMind account and agree to our <a href={URL + "/terms"} target='_blank' rel="noreferrer" style={{ color: '#4C6EA2', textDecoration: 'none', fontWeight: "700" }}>Terms of Service</a> and <a href={URL + "/privacy"} target='_blank' rel="noreferrer" style={{ color: '#4C6EA2', textDecoration: 'none', fontWeight: "700" }}>Privacy Policy</a>.</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} alignItems={'center'} position={'relative'} textAlign={'center'} xs={12} sm={12} md={4} lg={4} xl={4} gap={"20px"} mx={isSMobile ? "10px" : "0px"} mb={5}>
            <Grid container alignItems={'center'} justifyContent={'center'} xs={5.5}>
              <Box position={'absolute'} zIndex={"2"} width={isSMobile ? "180px" : "290px"} height={isSMobile ? "180px" : "290px"} borderRadius={"100%"} bgcolor={"rgb(191 159 92 / 10%)"}></Box>
              <img style={{ position: "relative", zIndex: "3", width: isSMobile ? "190px" : "347px", height: isSMobile ? "190px" : "347px" }} src={leftImage} />
            </Grid>
            <Grid container alignItems={'center'} justifyContent={'center'} xs={5.5}>
              <Box position={'absolute'} zIndex={"2"} width={isSMobile ? "180px" : "290px"} height={isSMobile ? "180px" : "290px"} borderRadius={"100%"} bgcolor={"rgba(92, 131, 191, 0.1)"}></Box>
              <img src={rightImage} style={{ position: "relative", top: "30px", zIndex: "3", width: isSMobile ? "160px" : "auto", height: isSMobile ? "160px" : "auto" }} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container justifyContent={'center'} alignItems={'center'} position={'relative'} sx={{ '@media (max-width: 900px)': { textAlign: 'center' } }} xs={10} sm={10} md={4} lg={4} xl={4} >
            <Box position={'absolute'} zIndex={"2"} width={"290px"} height={"290px"} borderRadius={"100%"} bgcolor={"rgb(191 159 92 / 10%)"}></Box>
            <img style={{ position: "relative", zIndex: "3", width: "347px", height: "347px" }} src={leftImage} />
          </Grid>
          <Grid sx={{ '@media (max-width: 500px)': { width: '98%' } }} sm={10} md={4} lg={3} xl={3} mb={5}>
            <Grid mt={5} gap={"32px"} padding={"32px"} border={"1px solid #E3DBD5"} boxShadow={"0px 5px 10px #EFE5DE"} borderRadius={"10px"} bgcolor={"primary.main"} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <Grid>
                <Typography
                  variant="h32Bold"
                  component="a"
                  onClick={() => navigate("/get-started")}
                  sx={{ color: "secondary.mainSand", textDecoration: 'none' }}
                >
                  pokamind
                </Typography>
                <Typography position={'absolute'} variant="body2SemiBold" sx={{ pb: 5, flexGrow: 1, color: "secondary.mainSand" }}>BETA</Typography>
              </Grid>
              {loading ? (
                <LoadingSpinner className="smaller_height"/>
              ) : emailSent ? (
                <Alert sx={{ textAlign: 'left', color: 'secondary.mainSand', backgroundColor: "#00ff7c42" }} variant="outlined">
                  <h3><strong>Check your email<br /></strong></h3>A sign in link has been sent to your email address. Please visit the link using this device. If you cannot find the email, be sure to check the spam folder.
                </Alert>
              ) : (
                <>
                  <Button sx={{ backgroundColor: "white", width: "100%", height: "auto", padding: "10px", gap: "12px", color: "primary.darkerBlue", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.12)", '&:hover': { backgroundColor: "#00376b", color: "secondary.mainSand" } }} variant="light" onClick={signInWithGoogle}>
                    <img src={googleIcon} style={{ width: '50px', height: '20px' }} alt='googleIcon' />
                    <Typography variant='sb16Bolder'>Continue with Google</Typography>
                  </Button>
                  <div className='horizontal-line' style={{ height: "1px", backgroundColor: "#5378B0" }}></div>
                  <form style={{ width: "100%" }} noValidate onSubmit={handleSignIn} className="animated-form">
                    <Grid marginBottom={"7%"} gap={"6px"}>
                      <Typography color={'secondary.mainSand'} variant='body2Bold'>Email Address</Typography>
                      <TextField
                        fullWidth
                        id="formBasicEmail"
                        placeholder="email@example.com"
                        type="email"
                        required
                        error={!emailValid}
                        helperText={!emailValid && "Please enter a valid email"}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailValid(e.target.checkValidity());
                        }}
                        InputProps={{ style: { backgroundColor: '#ffffff', height: "45px" } }}
                      />
                    </Grid>
                    <Button
                      fullWidth
                      sx={{ border: "1.5px solid white", padding: "8px", backgroundColor: 'transparent', color: 'secondary.mainSand' }}
                      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#00376b')}
                      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                      type="submit"
                    >
                      <Typography variant='sb16Bolder'>Sign In / Sign Up</Typography>
                    </Button>
                  </form>
                </>
              )}
            </Grid>
            <Grid textAlign={'center'} mt={3}>
              <Typography variant='body1Medium'>By continuing, you are setting up a PokaMind account and agree to our <a href={URL + "/terms"} target='_blank' rel="noreferrer" style={{ color: '#4C6EA2', textDecoration: 'none', fontWeight: "700" }}>Terms of Service</a> and <a href={URL + "/privacy"} target='_blank' rel="noreferrer" style={{ color: '#4C6EA2', textDecoration: 'none', fontWeight: "700" }}>Privacy Policy</a>.</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} position={'relative'} textAlign={'center'} alignItems={'center'} xs={10} sm={10} md={4} lg={4} xl={4} >
            <Box position={'absolute'} zIndex={"2"} width={"290px"} height={"290px"} borderRadius={"100%"} bgcolor={"rgba(92, 131, 191, 0.1)"}></Box>
            <img src={rightImage} style={{ position: "relative", top: "30px", zIndex: "3" }} />
          </Grid>
        </>
      )}
    </Grid>
  </Grid>
</div>

  );
}
export default Auth;
