import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { startOfDay, format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { renderToString } from "react-dom/server";


const SessionsAreaChart = ({ sessionTimes, startDate, endDate }) => {
  const [sessionData, setSessionData] = useState([]);

  useEffect(() => {
    const sessionCounts = {};
    
    // Ensure startDate and endDate are valid Date objects
    const validStartDate = new Date(startDate);
    const validEndDate = new Date(endDate);
  
    // Loop through sessionTimes and count sessions for each day
    sessionTimes.forEach((sessionTime) => {
      const sessionDate = startOfDay(new Date(sessionTime));
      if (sessionDate >= validStartDate && sessionDate <= validEndDate) {
        const formattedDate = format(sessionDate, "yyyy-MM-dd");
        // Increment session count for the respective day
        sessionCounts[formattedDate] = (sessionCounts[formattedDate] || 0) + 1;
      }
    });
  
    // Generate an array of all dates within the date range
    const datesInRange = [];
    let currentDate = validStartDate;
    while (currentDate <= validEndDate) {
      datesInRange.push(format(currentDate, "yyyy-MM-dd"));
      currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Increment currentDate by 1 day
    }
  
    // Populate session counts for all dates in the range, ensuring dates without sessions have a count of 0
    const chartData = datesInRange.map((date) => ({
      x: new Date(date).getTime(),
      y: sessionCounts[date] || 0, // If session count is undefined, default to 0
    }));
  
    // Set the chart data
    setSessionData(chartData);
  }, [sessionTimes, startDate, endDate]);
  
  
  
  const gradientColors = ['rgba(59, 135, 67, 0.13)' ,'rgba(59, 135, 67, 0.1)'];
  const gradientStops = [0, 99, 100];
  
  const options = {
    chart: {
      height: 150,
      type: "area",
      toolbar: {
        show: true,
        offsetY: -35,
        offsetX: 10,
        tools: {
          download: `<div>${renderToString(<FontAwesomeIcon size='lg' icon={faDownload} />)}</div>`,
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 2
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (value) {
          return format(new Date(value), "dd-MM-yyyy");
        },
      },
    },
    yaxis: {
      opposite: true,
      labels: {
        formatter: function (value) {
          return Math.floor(value);
        },
      },
      min: 0,
    },
    colors: ['rgba(59, 135, 67, 0.8)'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: gradientColors,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: gradientStops,
      }
    }
  };

  return <ReactApexChart options={options} series={[{ name: "Sessions", data: sessionData }]} type="area" height={350} />;
};

export default SessionsAreaChart;
