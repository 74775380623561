import { createTheme, responsiveFontSizes  } from "@mui/material/styles";

let theme = createTheme({
    palette: {
        background: {
            default: "#FFF7F1"
        },
        primary: {
            main: "#5C83BF",
            lightBlue: "#718BB1",
            darkerBlue: "#4C6EA2",
            lighterBackgroundBlue: "rgba(92, 131, 191, 0.1)",
            backgroundBlue: "rgba(92, 131, 191, 0.2)",
            fontBlue: "#4C6481"
        },
        secondary: {
            main: "#FFF7F1",
            lightSand: "#E9E2DD",
            seperatorSand: "#E3DBD5",
            superLightSand: "#FFF5EF",
            mainSand: "#FFF7F1",
        },
        otherColors: {
            seperatorWhite: "#E5E5E5",
            mainBodyDark: "#3F444B",
            white: "#FFFFF"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "12px",
                    borderRadius: "40px",
                    textTransform: "none", 
                    '&.Mui-disabled': {
                        opacity: 0.5,
                        color:"white",
                        pointerEvents: 'auto',
                        cursor: 'not-allowed',
                        '&:hover': {
                          backgroundColor: 'inherit',
                          color: 'inherit'
                        },
                      }
                },
                primary: {
                    background: "#5C83BF",
                    padding: "10px 26px 10px 22px",
                    color: "#FFFFFF",
                    border: "1.5px solid #4C6EA2",
                    "&:hover": {
                        background: "#FFFFFF",
                        color: "#4C6EA2",
                        border: "1.5px solid #4C6EA2",
                    },
                    "&.Mui-disabled": {
                        '&:hover': {
                          background: "#5C83BF",
                          color: '#FFFFFF'
                        }
                      }
                },
                secondary: {
                    background: "#FFFFFF",
                    padding: "10px 26px 10px 22px",
                    color: "#4C6EA2",
                    border: "1.5px solid #4C6EA2",
                    "&:hover": {
                        background: "#5C83BF",
                        color: "#FFFFFF",
                    },
                    "&.Mui-disabled": {
                      '&:hover': {
                        background: "#FFFFFF",
                        color: '#4C6EA2'
                      }
                    }
                },
                withIcon: {
                    padding: "8px, 24px, 8px, 20px",
                    background: "transparent",
                    color: "#4C6EA2",
                    border: "1.5px solid #4C6EA2",
                    "&:hover": {
                        background: "#5C83BF",
                        color: "#FFFFFF",
                    },  
                    "&.Mui-disabled": {
                        '&:hover': {
                          background: "transparent",
                          color: '#4C6EA2'
                        }
                      }
                },
                header: {
                    padding: "12px, 28px, 12px, 28px",
                    background: "#5C83BF",
                    color:"#FFF7F1",
                    borderRadius: "49px",
                    gap:"12px",
                    height: "48px",
                    "&:hover": {
                        background: "#5C83BF",
                        color: "#FFF7F1",
                    },
                    "&.Mui-disabled": {
                      '&:hover': {
                        background: "#5C83BF",
                        color: '#FFF7F1'
                      }
                    }
                },
                homeHeader: {
                    padding: "12px, 28px, 12px, 28px",
                    background: "#FFF7F1",
                    color:"#FFF7F1",
                    borderRadius: "50px !important",
                    gap:"12px",
                    height: "48px",
                    "&:hover": {
                        background: "#FFF7F1",
                        color: "#FFF7F1",
                    },
                    "&.Mui-disabled": {
                      '&:hover': {
                        background: "#FFF7F1",
                        color: '#FFF7F1'
                      }
                    }
                }
            }
        }
    },
    typography: {
        fontFamily: 'Nunito, Lato, Montserrat',
        h42Medium: {
            fontSize: "42px",
            fontWeight: "500",
            lineHeight: "55px"
        },
        h42SemiBold: {
            fontSize: "42px",
            fontWeight: "600",
            lineHeight: "55px"
        },
        h42Bold: {
            fontSize: "42px",
            fontWeight: "800",
            lineHeight: "55px"
        },
        h36Medium: {
            fontSize: "36px",
            fontWeight: "500",
            lineHeight: "47px"
        },
        h36SemiBold: {
            fontSize: "36px",
            fontWeight: "600",
            lineHeight: "47px"
        },
        h36Bold: {
            fontSize: "36px",
            fontWeight: "700",
            lineHeight: "47px"
        },
        h32Bold: {
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "42px"
        },
        h32Bolder: {
            fontSize: "32px",
            fontWeight: "800",
            lineHeight: "42px"
        },
        h32Regular: {
            fontSize: "32px",
            fontWeight: "400",
            lineHeight: "42px"
        },
        h32Medium: {
            fontSize: "32px",
            fontWeight: "500",
            lineHeight: "42px"
        },
        sb24Medium: {
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "32px"
        },
        sb24SemiBold: {
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "32px"
        },
        sb24Bold: {
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px"
        },
        sb24Bolder: {
            fontSize: "24px",
            fontWeight: "800",
            lineHeight: "32px"
        },
        sb20Regular: {
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "26px"
        },
        sb20Medium: {
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "26px"
        },
        sb20SemiBold: {
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "26px"
        },
        sb20Bold: {
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "26px"
        },
        sb20Bolder: {
            fontSize: "20px",
            fontWeight: "900",
            lineHeight: "26px"
        },
        sb16SemiBold: {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "18px"
        },
        sb16Bold: {
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "18px"
        },
        sb16Bolder: {
            fontSize: "16px",
            fontWeight: "800",
            lineHeight: "18px"
        },
        body1Regular: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "21px"
        },
        body1Medium: {
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "21px"
        },
        body1SemiBold: {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "21px"
        },
        body2Regular: {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "18px"
        },
        body2Medium: {
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "18px"
        },
        body2SemiBold: {
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "18px"
        },
        body2Bold: {
            fontSize: "14px",
            fontWeight: "900",
            lineHeight: "18px"
        },
        caption: {
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "16px"
        },
        content: {
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px"
        },
        medium18: {
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "18px"
        }
    }
    
})

theme = responsiveFontSizes(theme, {
    disableAlign: true, // Disable the alignment correction
    variants: ['h42Medium', 'h42SemiBold', 'h42Bold', 'h36Medium', 'h36SemiBold','h32Bold','h32Bolder', 'h36Bold', 'h32Regular', 'h32Medium','sb24Medium', 'sb24SemiBold', 'sb24Bold', 'sb24Bolder', 'sb20Regular', 'sb20Medium', 'sb20SemiBold', 'sb20Bold','sb20Bolder', 'sb16SemiBold','sb16Bold', 'sb16Bolder', 'body1Regular', 'body1Medium', 'body1SemiBold', 'body2Regular', 'body2Medium', 'body2SemiBold','body2Bold', 'caption', "content", "medium18"],
});

export default theme;