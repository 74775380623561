import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Modal
} from 'react-bootstrap';
import { useUser } from '../contexts/UserContext';
import { URL } from "../Helpers";
import { chunk } from 'lodash';
import { Link } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faBan, faAngleDown, faAngleUp, faLink } from '@fortawesome/free-solid-svg-icons';
import CancelAppointmentModal from '../components/CancelAppointment';
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import LoadingSpinner from './shared/LoadingSpinner';
import Carousel from 'react-bootstrap/Carousel';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
// import DatePicker from "react-datepicker";
import moment from 'moment'
import EditTherapistProfile from './EditTherapistProfile';
import './CustomDateTimePicker.scss';
import './style.scss';
import "./BookTherapy.scss";
import './style.scss';
import { Grid, Typography, useTheme, SvgIcon, Box, Button, Card, IconButton, Tooltip, CardMedia, CardContent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as TherapistsIcon } from '../data/psychology.svg';
import { ReactComponent as AppointmentsIcon } from '../data/appointments.svg';
import MyAvatar from "../data/appointments_avatar.png"
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Snackbar from '@mui/material/Snackbar';
import zIndex from '@mui/material/styles/zIndex';

const Appointments = () => {
  const { user } = useUser();
  const [therapistInfo, setTherapistInfo] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [view, setView] = useState("appointments");
  const [selectedDates, setSelectedDates] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(true)
  const [dateGroups, setDategroups] = useState();
  const [showDateTime, setShowDateTime] = useState(false);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [filter, setFilter] = useState('booked');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [selectedTimestamp, setSelectedTimestamp] = useState(null);
  const [openStates, setOpenStates] = useState({});
  const [formattedSlots, setFormattedSlots] = useState([])
  const [displayPicker, setDisplayPicker] = useState(false)
  const [times, setTimes] = useState([])
  const [availableTimes,setAvailableTimes] = useState([])
  const [selectedTimes, setSelectedTimes] = useState({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const [disabledDates, setDisabledDates] = useState([]);
  const [showTimePicker, setShowTimePicker] = useState(null);
  const [isTimeSelected, setIsTimeSelected] = useState(false)
  const [showPatientModal, setShowPatientModal ] = useState(false)
  const isMediumScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const [timeSlotId, setTimeSlotId] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  const [showError, setShowError] = useState(false)

  const customStyles = `
  .react-datepicker__week {
    margin-top: ${isMobile ? '3px' : isSmallScreen ? '8px' : '10px'};
    display: flex;
    gap: ${ isMobile ? '' : isSmallScreen ? '5px' : '10px'};
  }

  .react-datepicker__month-container{
    padding: 0px !important;
    background-color: white !important;
  }

  .react-datepicker{
    border: none !important;
    box-shadow: 0px 5px 10px #EFE5DE;
  }

  .react-datepicker__month{
    padding: 0px 10px 10px 10px !important;
  }

  .react-datepicker__header{
    border-radius: 10px 10px 0px 0px !important;
    padding: 10px 0px !important;
  }

  .react-datepicker__week:nth-child(1) {
    margin-top: ${isMobile ? '8px' : isSmallScreen ? '8px' : '10px'};
  }

  .rc-time-picker-panel-inner{
    width: 300px !important;
  }

  .rc-time-picker-clear{
    display: none !important;
  }

  .rc-time-picker-panel-select ul{
    display: grid;
    height: 229px;
    grid-template-columns: repeat(3, 1fr);
    margin: 5px 5px 5px -5px !important;
  }

  .rc-time-picker-panel-select{
    width: 100% !important;
    overflow: auto;
  }
  
  .rc-time-picker-panel-select li{
    width: 70px !important;
    background: #62C294;
    padding: 5px 10px 28px 3px !important;
  }

  .react-datepicker__day-name{
    margin-inline: ${ isMobile ? '' : isSmallScreen ? '0.6rem' :'1.5rem'};
  }

  .rc-time-picker-panel-combobox li::after {
    content: ":00";
  }

  .react-datepicker__day {
    width: ${isMobile ? '2.5rem !important' : isSmallScreen ? '3.5rem !important' : '5rem !important'};
    height: ${isMobile ? '3rem !important' : isSmallScreen ? '' : '4rem !important'};
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 10px rgba(189, 189, 189, 0.18);
    border-radius: 8px;
  }

  .rc-time-picker-panel-select-option-disabled{
    background: #5476AC !important;
    display: none !important;
  }
  
`;
  const { showBoundary } = useErrorBoundary();

  const handleDateClick = (date) => {
    setShowTimePicker(date); // Set the state to the clicked date to show its TimePicker
  };

  const fetchTimeSlots = useCallback(async () => {
    try {
      setLoading(true)
      const response = await fetch(`${URL}/therapists_timeslots/${user.uid}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
      });

      const data = await response.json();
      setTimeSlotId(data)
      if (!response.ok)
        throw new Error(data.message)
      if (data.therapistInfo) {
        setTherapistInfo(data.therapistInfo);
        setLoading(false);
      }
      if (data.availableSlots) {
        setFormattedSlots(data.availableSlots.map(slot => ({
          ...slot,
          timestamp: formatTimeslot(new Date(slot.time))
        })));
        setTimes(data.availableSlots.map(slot => slot.time))

        setDategroups(chunk(formattedSlots, 4));
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message)
      showBoundary(new Error('Error fetching time slots:'+ error.message))
    }
  }, [user])

  useEffect(() => {
    if (user)
      fetchTimeSlots()
  }, [user]);


const handleDateChange = (date) => {
  // Check if the clicked date is already selected
  const isDateSelected = selectedDates.some(selectedDate => selectedDate.getTime() === date.getTime());

  if (isDateSelected) {
    // If the date is already selected, remove it from the selectedDates array
    setSelectedDates(selectedDates.filter(selectedDate => selectedDate.getTime() !== date.getTime()));
  } else {
    // If the date is not selected, add it to the selectedDates array
    setSelectedDates([...selectedDates, date]);
  }
};

  
  const handleTimeChange = (time, selectedDate) => {
    // Check if the time already exists in the selected times for the selected date
    const isTimeSelected = selectedTimes[selectedDate] && selectedTimes[selectedDate].some(selectedTime => selectedTime.isSame(time, 'minute'));
    
    setDisplayButton(true)

    if (isTimeSelected) {
      setIsTimeSelected(true)
      // If the time is already selected, remove it from the selected times
      setSelectedTimes({
        ...selectedTimes,
        [selectedDate]: selectedTimes[selectedDate].filter(selectedTime => !selectedTime.isSame(time, 'minute'))
      });
    } else {
      // If the time is not already selected, add it to the selected times
      setSelectedTimes({
        ...selectedTimes,
        [selectedDate]: selectedTimes[selectedDate] ? [...selectedTimes[selectedDate], time] : [time]
      });
    }
  };
  
    // Function to disable dates before the current day
    const disablePastDates = (date) => {
      const today = new Date();
      return date < today;
    };
  
    // Function to disable dates if all times are selected for a day
    const disableSelectedDates = (date) => {
      const dateString = date.toDateString();
      const selectedTimesForDate = selectedTimes[dateString];
      const hasAvailableTimes = selectedTimesForDate && selectedTimesForDate.length > 0;
      return !hasAvailableTimes;
    };
    
    
  
    // Function to filter disabled dates
    const filterDisabledDates = useCallback(() => {
      const disabled = selectedDates.filter(disableSelectedDates);
      setDisabledDates(disabled);
    }, [selectedDates, selectedTimes]);
  
    // Update disabled dates when selected dates or times change
    useEffect(() => {
      filterDisabledDates();
    }, [selectedDates, selectedTimes, filterDisabledDates]);
  

  const disabledMinutes = (selectedHour) => {
    if (!selectedDates) return [];
  
    const selectedDateStr = moment.utc(selectedDates).startOf('day').hour(selectedHour);
    const nextDay = moment.utc(selectedDates).startOf('day').add(1, 'day').hour(selectedHour); 
  
    const availableMinutes = availableTimes
      .filter(time => moment.utc(time).isSame(nextDay, 'hour'))
      .map(time => moment.utc(time).minute());
  
    const allMinutes = Array.from({ length: 60 }, (_, i) => i);
  
    const disabledMinutes = allMinutes.filter(minute => !availableMinutes.includes(minute));
  
    return disabledMinutes;
  };

    
  const AvailableSlots = (selectedDate) => {
    if (!selectedDate || !times || times.length === 0) return [];
  
    // console.log("Selected Date:", selectedDate);
    const selectedDateStr = moment.utc(selectedDate).add(1, 'day').format("YYYY-MM-DD");
    // console.log("Selected Date String:", selectedDateStr);
  
    // Get all available hours for the selected date
    const availableHours = times
      .filter(time => moment.utc(time).isSame(moment.utc(selectedDateStr), 'day'))
      .map(time => moment.utc(time).hour());
  
    // Generate an array of disabled hours
    const disabledHours = Array.from({ length: 24 }, (_, i) => i).filter(hour => availableHours.includes(hour));
    // console.log("Disabled Hours:", disabledHours);
  
    return disabledHours;
  };
  
  const disabledHours = (selectedDate) => {
    if (!selectedDate || !times || times.length === 0) return [];
  
    // console.log("Selected Date:", selectedDate);
    const selectedDateStr = moment.utc(selectedDate).add(1, 'day').format("YYYY-MM-DD");
    // console.log("Selected Date String:", selectedDateStr);
  
    // Get all available hours for the selected date
    const availableHours = times
      .filter(time => moment.utc(time).isSame(moment.utc(selectedDateStr), 'day'))
      .map(time => moment.utc(time).hour());
    // console.log("Available Hours:", availableHours);
  
    // Generate an array of disabled hours excluding available hours and 8am to 8pm
    const disabledHours = Array.from({ length: 24 }, (_, i) => i)
      .filter(hour => availableHours.includes(hour) || hour < 8 || hour > 20); // Exclude unavailable hours and hours outside of 8am to 8pm
    // console.log("Disabled Hours:", disabledHours);
  
    return disabledHours;
  };

  const handleToggle = () => {
    setShowDateTimePicker(!showDateTimePicker);
  };

  const togglePatientInfo = (appointmentId) => {
    setOpenStates(prevStates => ({
      ...prevStates,
      [appointmentId]: !prevStates[appointmentId]
    }));
    if (!openStates[appointmentId]) {
      setShowPatientModal(true);
      console.log("patient",showPatientModal)

    } else {
      setShowPatientModal(false);
    }
  };

  const formatTimeslot = (dateObj) => {
    // Format the date
    const dateFormatter = new Intl.DateTimeFormat("en-UK", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const formattedDate = dateFormatter.format(dateObj);

    // Format the time
    const timeFormatter = new Intl.DateTimeFormat("en-UK", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
      timezone: "CET"
    });
    
    let formattedTime = timeFormatter.format(dateObj);

    // Check if the formatted time is "00:00 AM" or "00:00 PM" and change it to "12:00 AM" or "12:00 PM"
    if (formattedTime.startsWith("00:")) {
      formattedTime = formattedTime.replace("00:", "12:");
    }
    
    return {
      date: formattedDate,
      time: formattedTime,
    };
  }



  const fetchAppointments = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${URL}/get_therapist_appointments`, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
        },
      });
      const therapistAppointments = response.data;
      if (therapistAppointments.length === 0) {
        console.log("No appointments");
      } else {
        setAppointments(therapistAppointments);
        filterAppointments(therapistAppointments, filter);
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
    } finally {
      setLoading(false)
    }
  };

  const filterAppointments = (appointments, filter) => {
    // Filter by status or passed
    const now = new Date();
    const filtered = appointments.filter(appointment => {
      const appointmentDate = new Date(`${appointment.date}T${appointment.time}`);
      const oneHourAfterAppointment = new Date(appointmentDate.getTime() + (60 * 60 * 1000));
      if (filter === 'booked') {
        return appointment.status === 'booked' && now < oneHourAfterAppointment;
      } else if (filter === 'passed') {
        return appointment.status === 'booked' && now >= oneHourAfterAppointment;
      } else if (filter === 'cancelled') {
        return appointment.status === 'cancelled';
      }
      return true;
    });
    setFilteredAppointments(filtered);
  };

  // const filteredAvailableDates = availableTimes.map(time => moment.utc(time, 'ddd, DD MMM YYYY HH:mm:ss [GMT]'));

  // const isDateDisabled = (date) => {
  //   const dateStr = moment.utc(date).startOf('day');
  //   const nextDay = dateStr.clone().add(1, 'day'); // Get the next day
  //   return filteredAvailableDates.some(availableDate => availableDate.isSame(nextDay, 'day'));
  // };

  
    
  


  const handleDelete = async (timeslotID) => {
    try {
      const idToken = await user.getIdToken();

      const response = await fetch(`${URL}/delete_timeslot`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          "timeslotId": timeslotID,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setDategroups(prevDateGroups =>
        prevDateGroups.map(group =>
          group.filter(date => date.id !== timeslotID)
        )
      );
      await fetchTimeSlots()

    } catch (error) {
      console.error("Error deleting timeslot:", error);

    }
  };

  const openModal = (appointment) => {
    setSelectedAppointment(appointment);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedAppointment(null);
    setModalIsOpen(false);
  };

  useEffect(() => {
    setLoading(true)

    if (view === "appointments") {
      fetchAppointments();
    }

  }, [view, filter]);

  const handleCancelClick = (appointment) => {
    setSelectedAppointment(appointment);
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
    setSelectedAppointment(null);
  };

  const handleDeleteClick = (timeslotID, date) => {
    setSelectedTimeslot(timeslotID);
    setSelectedTimestamp(date);
    setShowConfirmModal(true);
  };

  const handleModalDeleteClick = () => {
    setShowConfirmModal(false);
    if (selectedTimeslot) {
      handleDelete(selectedTimeslot);
    }
  };

  const handleCancel = async (appointmentId, cancellationReason) => {
    try {
      setLoading(true)
      const idToken = await user.getIdToken();
      //getting date now and accounting for timezone
      const reactDate = Date.now()
      const response = await fetch(`${URL}/cancel_appointment`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          appointmentId,
          cancellationReason,
          'time_now': reactDate
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Cancellation result:", result);
      handleCloseCancelModal()
      setAppointments([])
      await fetchAppointments()
      setLoading(false)

    } catch (error) {
      console.error("Error cancelling appointment:", error);
      showBoundary(new Error("Error cancelling appointment: " + error))
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
    toggleDropdown();
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formattedDateTime = Object.entries(selectedTimes).map(([date, times]) => {
      return times.map(time => {
        // Check if time is a Moment.js object
        if (moment.isMoment(time)) {
          // Convert Moment.js object to string in the format 'HH:mm'
          time = time.format('HH:mm');
        }
  
        // Check if time is a string
        if (typeof time !== 'string') {
          console.error('Invalid time format:', time);
          return null; // Skip this time
        }
  
        // Parse the selected date string
        const selectedDate = moment.utc(date).format('YYYY-MM-DD');
  
        // Combine the date and time strings
        const combinedDateTime = (`${selectedDate}T${time}`)

        return combinedDateTime;
      }).filter(time => time !== null); // Remove null values (invalid times)
    }).flat();
  
    console.log('Formatted Date Time:', formattedDateTime);
  
    if (formattedDateTime.length === 0) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000); // 4 seconds
      return; // Stop further execution
    }
  
    const formData = {
      'times': formattedDateTime
    };
  
    console.log("Form Data:", formData); // Log the form data before sending
  
    try {
      const response = await fetch(`${URL}/add_update_timeslot`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify(formData),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        await fetchTimeSlots();
        setSelectedDates([]);
        setSelectedTimes({});
        setDisplayPicker(false);
        console.log("Request successful");
      } else {
        console.error(responseData.error);
      }
    } catch (error) {
      console.error('An error occurred while sending the request', error);
    }
  };
  

  
  const handleDeleteTime = async (hour, date) => {
    try {
      // Create a moment object for the selected date
      const selectedDateMoment = moment(date);
  
      // Set the hour for the selected date
      selectedDateMoment.set({ hour });
  
      // Generate the formatted date-time string
      const formattedDateTime = selectedDateMoment.format('ddd, DD MMM YYYY HH:mm:ss [GMT]');
      const timeslot = formattedSlots.find(slot => slot.time === formattedDateTime);
      console.log("timeslot",timeslot.id)
      console.log("timeslot",formattedSlots)
  
    // Filter out the selected time from the times array
    const updatedTimes = times.filter(time => time !== formattedDateTime);

    // Update the state with the new times array
    setTimes(updatedTimes);

    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    // Delete the timeslot from the backend
    const idToken = await user.getIdToken();
    const response = await fetch(`${URL}/delete_timeslot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({ timeslotId: timeslot.id  }), // Assuming the timeslot ID is the formatted date-time string
    });
    if (!response.ok) {
      throw new Error('Failed to delete timeslot');
    }

  } catch (error) {
    console.error('Error deleting timeslot:', error);
  }
};


  const currentDate = new Date();
  const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, currentDate.getDate());

  return (
    <>
      {view === 'profile' && (
        <EditTherapistProfile/>
      )}
    {view === "appointments" && (
    <div className="prevent_overflow">
      <Navbar />
      <Container className="d-flex flex-column content">
          {loading ? (
            <LoadingSpinner className="bigger_height" />
          ) : (
            <Grid container alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={"60px"} mt={"32px"}>
              <Grid display={'flex'} flexDirection={{xs:'column', md:'row'}} justifyContent={'center'} alignItems={'center'} gap={{xs:'30px', md:'20px'}}>
                <Grid position={'relative'}>
                  <Box position={'absolute'} left={{xs:'-23px', md:'-55px'}} zIndex={"2"} width={"180px"} height={"180px"} borderRadius={"100%"} bgcolor={"rgba(92, 131, 191, 0.1)"}></Box>
                  <img src={MyAvatar} alt="Avatar" style={{position:"relative",top:"30px", left: isMediumScreen ? "0px" : "-33px", zIndex:"3"}} />
                </Grid>
                <Grid display={'flex'} flexDirection={'column'} gap={"30px"}>
                  <Grid display={'flex'} alignItems={'center'}>
                  <Button sx={{color:"secondary.main"}} onClick={() => setView("profile")}>
                    <Box color={'#4C6EA2'}>
                      <SvgIcon component={TherapistsIcon} width="30px" height="30px"/>
                    </Box>
                    <Typography variant={isSmallScreen ? 'sb16Bolder' : 'sb24Bolder'}  sx={{color: "primary.darkerBlue"}}>My Profile</Typography>
                  </Button>
                  <div className="vertical-line" style={{background:"#E3DBD5", height:"2rem"}} />
                  <Button onClick={() => setView("appointments")}>
                  <Box   
                      sx={{
                        color: "white",
                        bgcolor: "primary.darkerBlue",
                        width: "42px",
                        height: "42px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center", 
                        alignItems: "center",
                      }}
                    >
                      <SvgIcon component={AppointmentsIcon} width="20px" height="20px"/>
                    </Box>
                    <Typography variant={isSmallScreen ? 'sb16Bold' : 'sb24Bold'} sx={{color: "primary.darkerBlue"}}>My Appointments</Typography>
                  </Button>
                  </Grid>
                  <Grid justifyContent={{xs:'center', md:'start'}} container gap={10} alignItems='center'>
                  <Grid width={"250px"}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter}
                        label="Filter"
                        onChange={(event) => handleFilterChange(event.target.value)}
                      >
                        <MenuItem fontSize={"16px"} fontWeight={"600"} value="booked" sx={{ '&:hover': { backgroundColor: '#00ff3a33' },'&.Mui-selected': { backgroundColor: '#00ff3a33' } }} selected={filter === 'booked'}>Confirmed</MenuItem>
                        <MenuItem fontSize={"16px"} fontWeight={"600"} value="passed" sx={{ '&:hover': { backgroundColor: '#6c757d1c' }, '&.Mui-selected': { backgroundColor: '#6c757d1c' } }} selected={filter === 'booked'}>Passed</MenuItem>
                        <MenuItem fontSize={"16px"} fontWeight={"600"} value="cancelled" sx={{ '&:hover': { backgroundColor: '#ff001826' }, '&.Mui-selected': { backgroundColor: '#ff001826' } }} selected={filter === 'booked'}>Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
              <Grid container mt={5} gap={"40px"} justifyContent={'center'}>
                <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} textAlign={'center'} gap={"10px"}>
                    <Typography variant='h32Bold'>Appointments Calendar</Typography>
                    <Typography variant='sb20Bold'>Choose a day from the calendar to set or view your availability</Typography>
                    <form onSubmit={handleSubmit}>
                      {/* <DatePicker
                        required={true}
                        value={selectedDates}
                        sort={true}
                        multiple
                        onChange={handleDateChange}
                        format="DD-MM-YYYY hh:mm A"
                        minDate={new Date()}
                        maxDate={maxDate}
                        className="custom-datetime-picker"
                        plugins={[
                          <TimePicker 
                            position="bottom"
                            hideSeconds
                          />,
                          <DatePanel 
                            markFocused 
                            sort="date"
                          />
                        ]}
                      /> */}
                      <div>
                      <style>{customStyles}</style>
                        <DatePicker
                          selected={null} 
                          onChange={handleDateChange}
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Select Date"
                          inline 
                          timeIntervals={15}
                          dayClassName={(date) =>
                            selectedDates.some((d) => d.getDate() === date.getDate()) ? 'selected' : ''
                          }
                          disabledDates={disabledDates}
                          filterDate={(date) => !disablePastDates(date)}

                        />
                        <div>
                          {selectedDates.map((date, index) => (
                          <div key={index}>
                          <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Button onClick={() => handleDateClick(date)} sx={{background:"white", borderRadius:"10px", padding:"10px", border:"1px solid #E3DBD5", boxShadow:"0px 5px 10px #E9E2DD"}}>
                              <Tooltip title="Pick time" arrow>
                                <Typography variant='sb20Bold'>{date.toDateString()}</Typography>
                              </Tooltip>
                              <Tooltip title="Remove" arrow>
                                <IconButton
                                  sx={{padding:"0px"}}
                                  onClick={() => handleDateChange(date)}
                                    >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Button>
                            <Grid display={selectedTimes[date] ? "flex" : "none"} gap={"10px"} m={2}>
                              {selectedTimes[date] && selectedTimes[date].map((time, timeIndex) => (
                                <Grid
                                  key={timeIndex}
                                  className="selected-time" // Add a class for selected times
                                  onClick={() => handleTimeChange(time, date)} // Toggle selection on click
                                >
                                  <Button sx={{background:"white", borderRadius:"10px", padding:"10px", border:"1px solid #E3DBD5", boxShadow:"0px 5px 10px #E9E2DD"}}>
                                    <Typography>
                                      {time.format('HH:mm')}
                                    </Typography>
                                  </Button>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                            <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                            {showTimePicker && showTimePicker.getTime() === date.getTime() && (
                              <Grid position={'absolute'}>
                              <button
                                onClick={() => {
                                  setShowTimePicker(false);
                                }}
                                style={{ position: "absolute", zIndex: 99999, top: "-3px", left: "295px", border: "1px solid #ccc", boxShadow:"0 1px 5px #ccc", backgroundColor: "#5C83BF", borderRadius:"0px 5px 5px 0px" }}
                              >
                                <Typography variant="sb20Bolder" color={"black"}>X</Typography>
                              </button>
                                <TimePicker
                                  style={{position:"relative", zIndex: 1}}
                                  open={true}
                                  onClickOutside={() => {
                                    setShowTimePicker(false);
                                  }} 
                                  onChange={(time) => handleTimeChange(time, date)}
                                  showSecond={false}
                                  showMinute={false}
                                  className="xxx"
                                  allowEmpty={true}
                                  minuteStep={60}
                                  format="HH:00"
                                  disabledMinutes={(selectedHour) => disabledMinutes(selectedHour)}
                                  disabledHours={() => disabledHours(date)}
                                />
                                  <Tooltip title="Close" arrow>
                                    <IconButton
                                      onClick={() => setShowTimePicker(false)}
                                      sx={{ 
                                        position:"absolute",
                                        left: "267px",
                                        top:"0px",
                                        padding: '0px', 
                                        zIndex: "9999",
                                        '&:hover': { background: 'transparent' } }}
                                    >
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                              </Grid>
                            )}
                              <Grid container my={2} width={"fit-content"} alignSelf={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={"white"} borderRadius={"10px"} padding={"10px"}>
                                <Typography mx={1}>Time Slots:</Typography>
                                {AvailableSlots(date).map((hour) => (
                                  <Grid width={"fit-content"} 
                                  key={hour}
                                  sx={{
                                    background: "white",
                                    borderRadius: "10px",
                                    padding: "10px",
                                    border: "1px solid #E3DBD5",
                                    boxShadow: "0px 5px 10px #E9E2DD",
                                    margin: "5px",
                                    cursor:"pointer",
                                    display:"flex",
                                    gap:"2px"
                                  }}
                                  onClick={() => handleDeleteTime(hour, date)} // Call handleDeleteTime with the selected hour and date
                                >
                                  <Typography>
                                    {hour}:00
                                  </Typography>
                                  <Tooltip title="Delete" arrow>
                                    <IconButton
                                      onClick={() => handleDeleteTime(hour, date)}
                                      sx={{ padding: '0px', '&:hover': { background: 'transparent' } }}
                                    >
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                ))}
                              </Grid>
                            </Grid>
                        </div>
                  ))}
                        </div>
                      </div>
                        {showAlert && (
                          <Snackbar
                          open={showAlert}
                          autoHideDuration={4000}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          style={{ marginBottom: '60px' }}
                        >
                          <Alert severity="success" icon={<CheckIcon fontSize="inherit" />}>
                            Time slot has been deleted.
                          </Alert>
                        </Snackbar>
                        )}
                        {showError && (
                          <Snackbar
                          open={showError}
                          autoHideDuration={4000}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          style={{ marginBottom: '60px' }}
                        >
                          <Alert severity="error">
                            You need to pick a time.
                          </Alert>
                        </Snackbar>
                        )}
                      <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>   
                        <Button variant='primary' type="submit" disabled={!displayButton}>
                          <Typography variant='sb16Bolder'>Submit</Typography>
                        </Button>
                      </Grid>
                  </form>
                </Grid>
              {filteredAppointments.length === 0 ? (
                <Grid display={'flex'} justifyContent={'center'} textAlign={'center'} my={5}>
                  {filter === 'booked' && <h2>Currently you don't have any confirmed appointments.</h2>}
                  {filter === 'passed' && <h2>Currently you don't have any passed appointments.</h2>}
                  {filter === 'cancelled' && <h2>Currently you don't have any cancelled appointments.</h2>}
                </Grid>
              ) : (
                filteredAppointments.map((appointment) => {
                  const appointmentDate = new Date(appointment.time);
                  const now = new Date();
                  const oneHourAfterAppointment = new Date(appointmentDate.getTime() + 60 * 60 * 1000); 
                  const isPastAppointment = now >= oneHourAfterAppointment;
                  return (
                    <>
                      <Grid display={'flex'} justifyContent={'center'}
                        lg={5.5}
                        md={5.8}
                        sm={12}
                        xs={12}
                        key={appointment.id}
                      >
                        <Card className="appointment-card" sx={{width:"100%"}}>
                          <CardContent sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"20px"}}>
                            {appointment.status === 'cancelled' && (
                                <Grid display={'flex'} width={"100%"} justifyContent={'space-evenly'}>
                                  <Grid width={"fit-content"}>
                                    <Typography variant='sb20Medium' color={"#4C6EA2"}>*Cancelled by {appointment.cancellation_issuer}</Typography>
                                  </Grid>
                                  <Grid
                                  style={{ fontSize: "17px", textDecoration: "underline", cursor: "pointer" }}
                                  onClick={() => openModal(appointment)}
                                  >
                                    <Typography color={"#4C6EA2"}>See the reason</Typography>
                                  </Grid>
                                </Grid>
                            )}
                          <Grid width={"100%"} display={'flex'} justifyContent={'space-between'}>
                            <h5 className={`${appointment.status === 'cancelled' || isPastAppointment ? 'text-muted' : ''}`}>
                              {appointment.user_email ? `${appointment.user_email.split('@')[0]}` : ''}
                            </h5>
                            <div className="vertical-line" style={{background:"#E3DBD5", height:"2rem"}} />
                            <h3
                              className={`comfortaa_font fw-bold ${appointment.status === 'cancelled' || isPastAppointment ? 'text-muted' : ''}`}
                            >
                              {new Date(appointment.time).toLocaleString()}
                            </h3>
                          </Grid>
                          <Grid display={appointment.status === 'cancelled' ? 'none' : ""} className="horizontal-line" style={{background:"#E3DBD5", height:"1px", width:"100%"}} />
                          {appointment.status !== 'cancelled' && filter !== 'passed' && (
                            <Grid display={'flex'} flexDirection={'column'} width={"100%"} gap={"10px"}>
                          <Grid width={"100%"} display={'flex'} alignItems={'center'} justifyContent={'space-evenly'}>
                              <Grid>
                                {appointment.status !== 'cancelled' && !isPastAppointment && (
                                  <Link
                                    style={{textDecoration:"none"}}
                                    href={appointment.meeting_link}
                                    className="text-blue fw-bold d-block"
                                    target="_blank" rel="noopener noreferrer"
                                  >
                                      <Button variant='primary' disabled = {isPastAppointment || appointment.status === 'cancelled'}>
                                      <Typography variant='sb16Bolder'
                                        sx={{ textDecoration: isPastAppointment || appointment.status === 'cancelled' ? 'line-through' : 'none'}}>
                                        Meeting link
                                      </Typography>
                                      </Button>
                                  </Link>
                                )}
                              </Grid>
                            <Grid>
                              {appointment.status !== 'cancelled' && (
                                <div className="d-flex justify-content-center">
                                    <Button variant='secondary'
                                      onClick={() => togglePatientInfo(appointment.id)}
                                    >
                                      <Typography variant='sb16SemiBold'>Patient Insights</Typography>
                                    </Button>
                                </div>
                              )}
                            </Grid>
                            {/* {openStates[appointment.id] && appointment.status !== 'cancelled' &&  (
                              <div className="mt-2">
                                {appointment.info_for_therapist !== undefined && appointment.info_for_therapist !== null
                                    ? appointment.info_for_therapist
                                    : 'Unfortunately, there are no insights available.'}
                              </div>
                            )} */}
                          </Grid>
                          <div className="horizontal-line" style={{background:"#E3DBD5", height:"1px", width:"100%"}} />
                          <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Grid>
                              {appointment.status === 'booked' && (
                              <Row className="d-flex mx-5 justify-content-center">
                                  <Button
                                    variant='secondary'
                                    sx={{
                                      '&:hover': {
                                        color: 'white !important',
                                        backgroundColor: '#919191 !important'
                                      },
                                      color: '#919191 !important',
                                      border: '1px solid #919191'
                                    }}
                                    onClick={() => handleCancelClick(appointment)}
                                  >
                                    <Typography variant='sb16Bold'>Reschedule or Cancel</Typography>
                                  </Button>
                              </Row>
                              )}
                            </Grid>
                          </Grid>
                            </Grid>
                          )}
                            {filter === 'passed' && (
                              <Grid display={'flex'} justifyContent={'space-evenly'}>
                                <div className="d-flex justify-content-center">
                                    <Button variant='secondary'
                                      onClick={() => togglePatientInfo(appointment.id)}
                                    >
                                      <Typography variant='sb16SemiBold'>Patient Insights</Typography>
                                    </Button>
                                </div>
                                <Row className="d-flex mx-5 justify-content-center">
                                  <Button
                                    variant='secondary'
                                    sx={{
                                      '&:hover': {
                                        color: 'white !important',
                                        backgroundColor: '#919191 !important'
                                      },
                                      color: '#919191 !important',
                                      border: '1px solid #919191'
                                    }}
                                    onClick={() => handleCancelClick(appointment)}
                                  >
                                    <Typography variant='sb16Bold'>Reschedule or Cancel</Typography>
                                  </Button>
                              </Row>
                              </Grid>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                              
        <Modal
          show={showPatientModal}
          onHide={() => setShowPatientModal(false)}          
          contentLabel="Patient Insights"
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <h3>Patient Insights</h3>
          </Modal.Header>
          {openStates[appointment.id] && appointment.status !== 'cancelled' &&  (
                              <Grid m={3}>
                                {appointment.info_for_therapist !== undefined && appointment.info_for_therapist !== null
                                    ? appointment.info_for_therapist
                                    : 'Unfortunately, there are no insights available.'}
                              </Grid>
                            )}
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button variant="secondary" onClick={() => setShowPatientModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
                    </>
                  );
                })
              )}
              </Grid>
            </Grid>
          )}
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          contentLabel="Cancellation Reason Modal"
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <h3>Cancellation Reason</h3>
          </Modal.Header>
          <div style={{ paddingLeft: "20px" }}>
            {selectedAppointment && (
              <p>{selectedAppointment.cancellation_reason}</p>
            )}
          </div>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button className="blue_btn" variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <CancelAppointmentModal
          show={showCancelModal}
          handleClose={handleCloseCancelModal}
          handleCancel={(cancellationReason) => handleCancel(selectedAppointment.id, cancellationReason)}
        />

      </Container>
      <Footer />
    </div>
      )}
    </>
  );
};
export default Appointments;
