import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

const CancelAppointment = ({ show, handleClose, handleCancel }) => {
  const [confirmedCancellation, setConfirmedCancellation] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');

  const handleConfirmation = () => {
    if (confirmedCancellation) {
      handleCancel(cancellationReason);
    } else {
      setConfirmedCancellation(true);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Typography variant='sb24Bold'>Appointment Cancellation</Typography>
      </Modal.Header>
      <Modal.Body>
        {!confirmedCancellation ? (
          <Grid display={'flex'} flexDirection={'column'} gap={"20px"}>
            <Typography variant='sb16SemiBold'>Are you sure you want to cancel this appointment?</Typography>
            <Grid display={'flex'} gap={"10px"}>
              <Button variant="primary" onClick={handleConfirmation}>
                <Typography variant='sb16Bolder'>Yes</Typography>
              </Button>{' '}
              <Button variant="secondary" onClick={handleClose}>
                <Typography variant='sb16Bolder'>No</Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid display={'flex'} flexDirection={'column'} gap={"10px"}>
            <Typography variant='body1Regular'>Please provide a reason for cancellation:</Typography>
            <Form.Control
              as="textarea"
              rows={3}
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
            />
            <Grid display={'flex'} justifyContent={"space-between"}>
              <Button variant="primary" onClick={handleConfirmation}>
                <Typography variant='sb16Bolder'>Cancel Appointment</Typography>
              </Button>{' '}
              <Button variant="secondary" onClick={() => setConfirmedCancellation(false)}>
              <Typography variant='sb16Bolder'>Go Back</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CancelAppointment;
