import animals from "../data/animals.png";
import journaling from "../data/journaling.png";
import realtime from "../data/realtime.png";

const texts = {
  homeCTA: {
    title: "Peek into your inner self,",
    titleRest: "in just 3 minutes!",
    description1: "Your 3-minute no-prep, no-fuss, video journaling companion.",
    description2: "All anonymous, all about you.",
    buttonLabel: "Get Started!",  
  },
  homeCTAStartSession: {
    title: "Dive in,",
    titleRest: "it's only 3 minutes!",
    description1: "Got a camera and mic? Awesome! Fire them up for the best experience.",
    description2: "Camera shy? No problem! Just your voice is cool too - though our AI might not work as well to analyze your communication styles.",
    buttonLabel: "Start",
  },
  homeCards: [
    {
      id: 1,
      title: "Express Yourself, Anonymously",
      description: "In just 3 minutes, pour your heart out through our video journaling. Our AI will be your private mood analyst.",
      imageUrl: journaling,
    },
    {
      id: 2,
      title: "Instant Insights",
      description: "Get immediate, expert insights into your communication styles as you vent. Our AI works in real-time to foster self-reflection and growth.",
      imageUrl: realtime ,
    },
    {
      id: 3,
      title: "Communication Styles to Colours!",
      description: "Your communication styles transform into funky animal avatars that you can collect, reflecting your state of mind. Visualize your inner world in a fun, vibrant way.",
      imageUrl: animals,
    },
  ],
    overview: {
      sessionOverview: "Session Overview",
      lowest: "Lowest",
      highest: "Highest",
      energyLevelHeader: "Mood Level",
      improvementsHeader: "Improvements",
      energyLevelDescription: "Showing the valence/positivity of your communication styles in this session based on words you said, facial expression and tone.",
      improvementsDescription: "The negative communication styles that you have shown less of compared to your previous session.",
      emotionTimeline: "Communication Styles Timeline",
      emotionTimelineDescription: "The percentage of communication styles that you have shown during the session, accuracies of the communication styles are affected if the camera was not on.",
      proceedToAnalysis: "Go to Analysis"
    },
    analysis: {
      meetYourMoodRep: "Meet your MoodRep!",
      topicsSummary: "Topics Summary",
      share: "Download",
      back: "< Go back",
      toDBT : "Skill to Practice",
    },
    DBTAdvice: {
      skillToPractice: "Skill to Practice ",
      share: "Download",
      backToAnalysis: "< Go back",
      backToHome : "Back to myMood",
    },
    adminView: {
      adminTitle:  "Admin View",
      avgEnergyLevelHeader: "Average Energy Level",
      avgEnergyLevelDescription: "Showing the average energy level of the users.",
      lowest: "Lowest",
      highest: "Highest",
      improvedEmotionsHeader: "Improved Communication Styles",
      avgEmotionalTimelineHeader: "Average Communication Styles Timeline",
      improvedEmotionsDescription: "Showing the number of each improved communication style.",
      avgDuraiton: "Showing the average duration of the sessions.",
      avgDuraitonTitle: "Average Session Duration",
      topicsTitle: "Topics Average",
      topicsDescription: "Showing the average of the selected topics for sessions.",
      sessionsTitle: "Number of Sessions Over Time",
      sessionsDescription: "Showing the number of session per day.",
      energyPerDayTitle: "Energy Levels Over Time",
      energyPerDayDescription: "Showing the energy levels per day.",
      scoreTitle: "Improvement Score",
      scoreDescription: "Showing the improvement score for the top 5communication styles."
    }
  };
  
export default texts;
