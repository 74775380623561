import React from 'react';
import { Navigate, Outlet,useLocation } from 'react-router-dom';
import { useUser } from './contexts/UserContext';

const ProtectedTherapistRoute = (props) => {
  const { role } = useUser();
  const location = useLocation();

  if (role === 'therapist') {
    return <Outlet />;
  } else {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
};

export default ProtectedTherapistRoute;
