import { URL } from "../../Helpers";
import { useMediaQuery } from 'react-responsive';
import { Typography, Button, Grid, SvgIcon, Link, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as MySessionBlue } from '../../data/mysession-blue-icon.svg';
import "./HomeFooter.scss";
import { useNavigate } from "react-router-dom";
import TranslateComponent from '../shared/Translate';
import { ReactComponent as FullBlue } from '../../data/fullblue.svg';
import { ReactComponent as IconBlue } from '../../data/iconblue.svg';

const HomeFooter = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const navigate = useNavigate()
  return (
    <Grid width={"100%"} className="home_footer">
      {isMobile ? (
        <Grid width={"100%"}>
          <Grid sx={{ display: "flex", justifyContent: "center", columnGap: 3, pb: 1, pt: 2 }} >
            <Link className="animated-text-home" href={URL + "/terms"} sx={{ color: "primary.main", textDecoration: 'none' }}>
              <Typography variant="sb16Bolder">Terms and Conditions</Typography>
            </Link>
            <Link className="animated-text-home" href={URL + "/privacy"} sx={{ color: "primary.main", textDecoration: 'none' }}>
              <Typography variant="sb16Bolder">Privacy Policy</Typography>
            </Link>
            <Link className="animated-text-home" to="/faq" component={RouterLink} sx={{ color: "primary.main", textDecoration: 'none' }}>
              <Typography variant="sb16Bolder">FAQ</Typography>
            </Link>
            <Link className="animated-text-home" to="/sos" component={RouterLink} sx={{ color: "#BB5959", textDecoration: 'none' }}>
              <Typography variant="sb16Bolder">SOS</Typography>
            </Link>
          </Grid>
          <div className="horizontal-line" style={{ background: "rgba(92, 131, 191, 0.2)" }} />
          <Grid sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
            <div>
            <Box display={"flex"} height={"35px"} width={"fit-content"} overflow={"hidden"} alignItems={"center"} position={"relative"} right={"14px"}>
              <SvgIcon component={IconBlue} inheritViewBox style={{width:"50px", height:"auto"}}/>
              {/* <SvgIcon component={FullBlue} inheritViewBox style={{width:"125px", height:"120px"}}/> */}
              <Typography
                variant="sb24Bold"
                component={RouterLink}
                to="/get-started"
                sx={{ color: "rgb(92, 131, 191)", textDecoration: 'none', paddingBottom:"5px" }}
              >
                pokamind
              </Typography>
              {/*<Typography variant="body2SemiBold" sx={{ pb: 5, flexGrow: 1, color: "primary.darkerBlue" }}>BETA</Typography> */}
            </Box>
              <Typography color={"primary.main"} variant="body1SemiBold">2023 PokaMind ®</Typography>
            </div>
            <div>
              <Button
                to="/session"
                component={RouterLink}
                sx={{
                  m: 0,
                  gap: "8px",
                  color: "primary.backgroundBlue",
                  border: "1.5px solid #5C83BF",
                  "&:hover": {
                    color: "primary.backgroundBlue",
                    background: "none"
                  }
                }}
              >
                <SvgIcon component={MySessionBlue} inheritViewBox />
                <Typography className="animated-text-home" variant="sb16Bolder" sx={{ color: "primary.main", textTransform: "none" }} >Start Session</Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid>
            <Box display={"flex"} height={"35px"} width={"fit-content"} overflow={"hidden"} alignItems={"center"} position={"relative"} right={"14px"}>
              <SvgIcon component={IconBlue} inheritViewBox style={{width:"50px", height:"auto"}}/>
              {/* <SvgIcon component={FullBlue} inheritViewBox style={{width:"125px", height:"120px"}}/> */}
              <Typography
                variant="sb24Bold"
                component={RouterLink}
                to="/get-started"
                sx={{ color: "rgb(92, 131, 191)", textDecoration: 'none', paddingBottom:"5px" }}
              >
                pokamind
              </Typography>
              {/*<Typography variant="body2SemiBold" sx={{ pb: 5, flexGrow: 1, color: "primary.darkerBlue" }}>BETA</Typography> */}
            </Box>
            <Grid>
              <Typography color={"primary.main"} variant="body1SemiBold">2023 PokaMind ®</Typography>
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", columnGap: 3 }}>
            <Link className="animated-text-home" href={URL + "/terms"} target="_blank" sx={{ color: "primary.main", textDecoration: 'none' }}>
              <Typography variant="sb16Bolder">Terms and Conditions</Typography>
            </Link>
            <Link className="animated-text-home" href={URL + "/privacy"} target="_blank" sx={{ color: "primary.main", textDecoration: 'none' }}>
              <Typography variant="sb16Bolder">Privacy Policy</Typography>
            </Link>
            <Link className="animated-text-home" to="/faq" component={RouterLink} sx={{ color: "primary.main", textDecoration: 'none' }}>
              <Typography variant="sb16Bolder">FAQ</Typography>
            </Link>
            <Link className="animated-text-home" to="/sos" component={RouterLink} sx={{ color: "#BB5959", textDecoration: 'none' }}>
              <Typography variant="sb16Bolder">SOS</Typography>
            </Link>
          </Grid>
          <Grid>
            <Button
              to="/session"
              component={RouterLink}
              sx={{
                m: 0,
                gap: "8px",
                color: "primary.backgroundBlue",
                border: "1.5px solid #5C83BF",
                "&:hover": {
                  color: "primary.backgroundBlue",
                  background: "none"
                }
              }}
            >
              <SvgIcon component={MySessionBlue} inheritViewBox />
              <Typography className="animated-text-home" variant="sb16Bolder" sx={{ color: "primary.main", textTransform: "none" }} >Start Session</Typography>
            </Button>
          </Grid>
        </>
      )}
      <TranslateComponent />

    </Grid>
  );
};

export default HomeFooter;