import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from './contexts/UserContext';

const ProtectedUserRoute = (props) => {
  const { role } = useUser();
  const location = useLocation();

  if (role === 'user') {
    return <Outlet />;
  } else {
    return <Navigate to="/auth" state={{ from: location }} replace />;

  }
};

export default ProtectedUserRoute;