import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import { renderToString } from 'react-dom/server';
import "./AdminView.scss"
import { styled } from '@mui/system';

const StyledChart = styled(Chart)(({ theme }) => ({
  '& .apexcharts-bar-area': {  // Target the first bar
    width:"50px"
  },
  '& .apexcharts-bar-area:nth-child(1)': {  // Target the first bar
    fill: colorsMap['first'],  // Fill color for the first bar
    stroke: "#689C64", // Border color for the first bar
    strokeWidth: 1, // Border width
    width:"50px"
  },
  '& .apexcharts-bar-area:nth-child(2)': {  // Target the second bar
    fill: colorsMap['second'],  // Fill color for the second bar
    stroke: "#9E9567", // Border color for the first bar
    strokeWidth: 1, // Border width
  },
  '& .apexcharts-bar-area:nth-child(3)': {  // Target the third bar
    fill: colorsMap['third'],  // Fill color for the third bar
    stroke: "#7F4A89", // Border color for the first bar
    strokeWidth: 1, // Border width
  },
  '& .apexcharts-bar-area:nth-child(4)': {  // Target the third bar
    fill: colorsMap['fourth'],  // Fill color for the third bar
    stroke: "#7792d8", // Border color for the first bar
    strokeWidth: 1, // Border width
  },
  '& .apexcharts-bar-area:nth-child(5)': {  // Target the third bar
    fill: colorsMap['fifth'],  // Fill color for the third bar
    stroke: "#d87777", // Border color for the first bar
    strokeWidth: 1, // Border width
  },
  '& .apexcharts-xaxis-label ' : {
    fontSize: '14px',  // Font size
    fontWeight: 700,    // Font weight
    fill: '#4C6EA2',   // Text color
    fontFamily: 'Nunito !important'
  }
}));

const colorsMap = {
  first: '#81D073',
  second: '#D8C677',
  third: '#A54FAD',
  fourth: '#7792d8',
  fifth: '#d87777'
};



const EmotionVerticalBarChart = ({ emotionCountsChartData, avgEmotionDataLine }) => {

    const calculateEmotionAverages = (avgEmotionDataLine) => {
      const emotionCounts = {};
      let totalCount = 0;
    
      avgEmotionDataLine.forEach((data) => {
        Object.keys(data).forEach((emotion) => {
          if (emotion !== 'dayKey') {
            emotionCounts[emotion] = emotionCounts[emotion] || 0;
            emotionCounts[emotion] += data[emotion];
            totalCount += data[emotion];
          }
        });
      });
    
      const emotionPercentages = {};
      Object.keys(emotionCounts).forEach((emotion) => {
          const percentage = (emotionCounts[emotion] / totalCount) * 100;
          emotionPercentages[emotion] = parseFloat(percentage.toFixed(2));
        });
    
      return emotionPercentages;
  };
  
  const emotionPercentages = calculateEmotionAverages(avgEmotionDataLine);

  let averageEmotions = calculateEmotionAverages(avgEmotionDataLine);
  
  
  let chartData = emotionCountsChartData
    .slice(0, 5)
    .map((data) => ({
      emotion: data.emotion,
      count: data.count,
    }));
  const [chartWidth, setChartWidth] = useState(500); // Initial width

  useEffect(() => {
    const handleResize = () => {
      var screenWidth = window.innerWidth;

      if (screenWidth <= 678) {
        if (screenWidth <= 500) {
            if (screenWidth <= 420) {
                setChartWidth(260);
            } else {
                setChartWidth(320);
            }
        } else {
            setChartWidth(410);
        }
    } else if (screenWidth >= 900 && screenWidth <= 1340) {
        setChartWidth(400);
    } else {
        setChartWidth(500);
    }
    };
  
    window.addEventListener("resize", handleResize);
  
    handleResize();
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  

const options = {
  chart: {
    height: 340,
    type: "bar",
    toolbar: {
      show: true,
      offsetY: -35,
      offsetX: window.innerWidth <= 700 ? 20 : 30, // Update offsetX based on screen size
      tools: {
        download: `<div>${renderToString(<FontAwesomeIcon size='lg' icon={faDownload} />)}</div>`,
      },
    },
  },
  plotOptions: {
      bar: {
        barWidth: "30px",
        borderRadius: 3,
        width:"30px"
      },
    },
  stroke: {
      show: true,
      width: 1,
      colors: ["black"],
    },
  xaxis: {
    categories: chartData.map((data) => data.emotion),
    position: 'bottom',
  },
  fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.2,
        opacityFrom: 0.3,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  dataLabels: {
      enabled: true,
      formatter: function(val) {
        return val;
      },
      style: {
        fontSize: '14px',
        colors: ["white"],
      },
    },
};

const sortedEmotionPercentages = Object.entries(emotionPercentages)
  .sort(([,a],[,b]) => b-a)
  .reduce((r, [k,v]) => ({ ...r, [k]: v }), {});

const seriesData = Object.entries(sortedEmotionPercentages)
  .slice(0, 5) 
  .map(([emotion, value]) => ({
    emotion,
    value: value, 
  }));

const series = [{
  name: "Decreased by",
  data: seriesData.map(item => item.value),
}];


  return <StyledChart options={options} series={series} type="bar" height={335} width={chartWidth} 
  />;
};

export default EmotionVerticalBarChart;