import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import { db } from "../Firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useUser } from '../contexts/UserContext';
import { URL } from '../Helpers';
import './style.scss';
import { useLocation } from 'react-router-dom';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box, FormControl, FormGroup, FormLabel, Grid, Typography, Button, Alert, Slider, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const CustomSlider = styled(Slider)(() => ({
  '& .MuiSlider-rail': {
    // Calculate color based on value
    background: `linear-gradient(to right, green 25%, red 75%)`,
    height:"10px",
    paddingLeft:"10px"
  },
  '& .MuiSlider-track': {
    // Calculate color based on value
    background: `transparent`,
    height:"10px"
  },
    '& .MuiSlider-thumb': {
    // Calculate color based on value
      width:"25px",
      height:"25px"
  },
  '& .MuiSlider-mark': {
    // Calculate color based on value
      backgroundColor:"#5C83BF",
      marginLeft:"5px"
  }
}));

const CustomSelect = styled(Select)(() => ({
  '& .select__multi-value': {
    // Calculate color based on value
      backgroundColor: "rgba(92, 131, 191, 0.1)",
      border: "2px solid rgba(76, 110, 162, 1)",
      padding: "1px 2px",
      borderRadius: "10px"
  },
  '& .select__multi-value__remove': {
    borderRadius: "10px"
  }
}));


const goalsOptions = [
  { value: 'Reduce Anxiety', label: 'Reduce Anxiety' },
  { value: 'Improve Mood', label: 'Improve Mood' },
  { value: 'Better Sleep', label: 'Better Sleep' },
  { value: 'Increase Confidence', label: 'Increase Confidence' },
];

const conditionsOptions = [
  { value: 'Anxiety', label: 'Anxiety' },
  { value: 'Depression', label: 'Depression' },
  { value: 'Insomnia', label: 'Insomnia' },
  { value: 'PTSD', label: 'PTSD' },
];

const notificationPreferences = [
  { value: 'Email', label: 'Email' },
  { value: 'SMS', label: 'SMS' },
  { value: 'Push Notifications', label: 'Push Notifications' },
];

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

const stripePromise = loadStripe(process.env.REACT_APP_stripeKey);
// const stripePromise = loadStripe('pk_live_51Nzx2CG41FjTcm893cKCJmE7RqORF8fbdRstkWU1c1MxkjvFp8W9iqpEJb0A9ijdc8U9HnLUiJ1fbfSbVWrFCYMu00mxV5LLcy');

const UserProfileComponent = () => {
  const { user, tier, premium_status, role } = useUser();
  const [profileDetails, setProfileDetails] = useState({
    displayName: '',
    goals: [],
    conditions: [],
    notificationPref: [],
    age: '',
    gender: '',
    stressLevel: '',
    recurringPayments: true,

  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const location = useLocation()
  const premiumSectionRef = useRef()
  const [premiumLoading, setPremiumLoading] = useState(false)
  const stripe = useStripe()
  const [isHovered, setIsHovered] = useState(false);
  const [initialProfileDetails, setInitialProfileDetails] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
  const Fade = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
  
    React.useEffect(() => {
      setIsVisible(true);
    }, []);
  
    return (
      <div style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 0.9s ease' }}>
        {children}
      </div>
    );
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    let unsubscribe;

    if (user?.uid) {
      const docRef = doc(db, "users", user.uid);
      const storage = JSON.parse(sessionStorage.getItem(`userProfile_${user.uid}`));
      if (storage) {
        const userProfile = {
          displayName: storage.displayName || '',
          age: storage.age || '',
          gender: genderOptions.find(option => option.value === storage.gender) || '',
          stressLevel: storage.stressLevel || '',
          recurringPayments: storage.recurringPayments === true || storage.recurringPayments === 'true',
          goals: Array.isArray(storage.goals) ? storage.goals.map(goal => ({ value: goal, label: goal })) : [],
          conditions: Array.isArray(storage.conditions) ? storage.conditions.map(condition => ({ value: condition, label: condition })) : [],
          notificationPref: Array.isArray(storage.notificationPref) ? storage.notificationPref.map(pref => ({ value: pref, label: pref })) : [],
        };
        setProfileDetails(userProfile);
        setInitialProfileDetails(userProfile); // Set the initial state
      } else {
        unsubscribe = onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            const userProfile = {
              displayName: data.displayName || '',
              age: data.age || '',
              gender: genderOptions.find(option => option.value === data.gender) || '',
              stressLevel: data.stressLevel || '',
              recurringPayments: data.recurringPayments === true || data.recurringPayments === 'true',
              goals: Array.isArray(data.goals) ? data.goals.map(goal => ({ value: goal, label: goal })) : [],
              conditions: Array.isArray(data.conditions) ? data.conditions.map(condition => ({ value: condition, label: condition })) : [],
              notificationPref: Array.isArray(data.notificationPref) ? data.notificationPref.map(pref => ({ value: pref, label: pref })) : [],
            };
            setProfileDetails(userProfile);
            setInitialProfileDetails(userProfile); // Set the initial state
          } else {
            console.error("No such document!");
          }
        });
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);
  useEffect(() => {
    //if URL has #premium_seubscription scroll to that section
    if (location && location.hash === '#premium_subscription' && premiumSectionRef.current) {
      premiumSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }

  }, [location])

  const handleChange = (name, value) => {
    setProfileDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handlePremium = async () => {
    setPremiumLoading(true);
    // Redirect to the
    try {
      if (role !== "admin" && tier === "free") {
        // Navigate to the plans page
        window.location.href = "/plans";
      } else if (role !== "admin" && tier === "premium" && premium_status === "active") {
        // Navigate to the Stripe Customer Portal for managing the subscription
        window.location.href = process.env.REACT_APP_StripeCustomerPortal + `?prefilled_email=${user.email}`;
      } else if (tier === "premium" && premium_status !== "active") {
        // Navigate to the Stripe Customer Portal to fix the subscription
        window.location.href = process.env.REACT_APP_StripeCustomerPortal + `?prefilled_email=${user.email}`;
      }
      // setSuccess('redirected successfully.');
    } catch (error) {
      setError('Failed to redirect to Stripe. Please try again later.');
      console.error("Error redirecting : ", error);
    } finally {
      setPremiumLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
  
    // Convert selected options to just their values before submitting
    const profileDetailsToSubmit = {
      ...profileDetails,
      age: parseInt(profileDetails.age),
      gender: profileDetails.gender.value,
      goals: profileDetails.goals.map(option => option.value),
      conditions: profileDetails.conditions.map(option => option.value),
      notificationPref: profileDetails.notificationPref.map(option => option.value),
      recurringPayments: profileDetails.recurringPayments === true || profileDetails.recurringPayments === 'true',
    };
  
    try {
      const response = await fetch(`${URL}/updateProfile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify(profileDetailsToSubmit),
      });
  
      if (!response.ok) {
        setSnackbarSeverity('error');
        setSnackbarMessage('Failed to update profile. Please try again.');
        throw new Error('Failed to update profile details.');
      }
      // Store the updated profile data in the session storage
      sessionStorage.setItem(`userProfile_${user.uid}`, JSON.stringify(profileDetailsToSubmit));
  
      setSuccess('Profile updated successfully.');
      setSnackbarSeverity('success');
      setSnackbarMessage('Profile updated successfully.');
      // Show success alert
      setTimeout(() => {
        setSuccess('');
      }, 3000); // Hide success alert after 3 seconds
    } catch (error) {
      setError('Failed to update profile. Please try again.');
      console.error("Error updating document: ", error);
      // Show error alert
      setTimeout(() => {
        setError('');
      }, 3000); // Hide error alert after 3 seconds
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error || success) {
      setSnackbarMessage(error || success);
      setSnackbarSeverity(error ? 'error' : 'success');
      setOpenSnackbar(true);
    }
  }, [error, success]);
  

  console.log("profileDetails.stressLevel",profileDetails.stressLevel)
  const hasChanges = JSON.stringify(profileDetails) !== JSON.stringify(initialProfileDetails);

  return (
    <div className="prevent_overflow">
      <Navbar />
      <Grid container justifyContent={'center'} alignItems={'center'} my={5}>
        <Grid textAlign={'center'} xs={12} mb={5}>
         <Typography variant='h32Bold' color={"#4C6EA2"}>My Profile</Typography>
        </Grid>
        <Grid alignSelf={'center'} xs={11} sm={10} md={8} lg={6} xl={5} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <form onSubmit={handleSubmit}>
        <FormControl sx={{width:"100%", gap:"40px"}}>
        <Grid width={"100%"} bgcolor={"white"} alignSelf={'center'} borderRadius={"10px"} border={"1px solid #E3DBD5"} boxShadow={"0px 5px 10px #EFE5DE"} padding={{xs: "10px 0px 20px", sm: "40px"}}display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          <Grid width={"100%"} container padding={"30px"}  justifyContent={'center'} alignItems={'center'}>
          <Grid alignSelf={'start'} xs={12} lg={11} textAlign={'center'} mb={3}>
            <Typography variant='sb24SemiBold' color={"#4C6EA2"}>Personal Information</Typography>
          </Grid>
          <Grid xs={12} lg={11}>
            <FormGroup sx={{width:"100%"}} className="mb-4" controlId="displayName">
              <FormLabel 
                aria-label='Displayed Name' 
                column 
                sm={3} 
                lg={2} 
                htmlFor="displayName"
              >
                Displayed Name
              </FormLabel>
              <input 
                id="displayName"
                style={{ padding: "5.5px 8px", borderRadius: "4px", border: "1px solid hsl(0, 0%, 80%)" }} 
                type="text" 
                placeholder="Enter displayed name" 
                name="displayName" 
                value={profileDetails.displayName} 
                onChange={(e) => handleChange(e.target.name, e.target.value)} 
              />
            </FormGroup>
          </Grid>
          <Grid xs={12} lg={11}>
            <FormGroup sx={{width:"100%"}} className="mb-4" controlId="age">
            <FormLabel 
              aria-label='Age' 
              column 
              sm={3} 
              lg={2}
              htmlFor="age"
            >
              Age
            </FormLabel>
            <input 
              id="age"
              style={{ padding: "6px 8px", borderRadius: "4px", border: "1px solid hsl(0, 0%, 80%)" }} 
              type="number" 
              placeholder="Enter age" 
              name="age" 
              value={profileDetails.age} 
              onChange={(e) => handleChange(e.target.name, e.target.value)} 
            />
            </FormGroup>
          </Grid>
          <Grid xs={12} lg={11}>
            <FormGroup sx={{width:"100%"}} className="mb-4" controlId="gender">
              <FormLabel aria-label='Gender' column sm={3} lg={2} htmlFor="gender">Gender</FormLabel>
                <Select
                  id="gender"
                  name="gender"
                  options={genderOptions}
                  className="basic-single-select"
                  classNamePrefix="select"
                  value={profileDetails.gender}
                  onChange={(selectedOption) => handleChange('gender', selectedOption)}
                />
            </FormGroup>
            </Grid>
            <Grid xs={12} lg={11}>
            <FormGroup sx={{width:"100%"}} className="mb-5" controlId="notificationPref">
              <FormLabel aria-label='Notification Preferences' column sm={3} lg={2} htmlFor="notificationPref">Notification Preferences</FormLabel>
                <CustomSelect
                  id="notificationPref"
                  isMulti
                  name="notificationPref"
                  options={notificationPreferences}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={profileDetails.notificationPref}
                  onChange={(selectedOption) => handleChange('notificationPref', selectedOption)}
                />
            </FormGroup>
            </Grid>
            <Grid alignSelf={'start'} xs={12} lg={10} textAlign={'center'} mb={3}>
              <Typography variant='sb24SemiBold' color={"#4C6EA2"}>Goals and Preferences</Typography>
            </Grid>            
          <Grid xs={12} lg={11}>
            <FormGroup sx={{width:"100%"}} className="mb-4" controlId="goals">
              <FormLabel aria-label='Goals for Mental Health' column sm={3} lg={2} htmlFor="goals">Goals for Mental Health</FormLabel>
                <CustomSelect
                  id="goals"
                  isMulti
                  name="goals"
                  options={goalsOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={profileDetails.goals}
                  onChange={(selectedOption) => handleChange('goals', selectedOption)}
                />
            </FormGroup>
            </Grid>
            <Grid xs={12} lg={11}>
            <FormGroup sx={{width:"100%"}} className="mb-4" controlId="conditions">
              <FormLabel aria-label='Diagnosed Conditions' column sm={3} lg={2} htmlFor="conditions">Diagnosed Conditions</FormLabel>
                <CustomSelect
                  id="conditions"
                  isMulti
                  name="conditions"
                  options={conditionsOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={profileDetails.conditions}
                  onChange={(selectedOption) => handleChange('conditions', selectedOption)}
                />
            </FormGroup>
            </Grid>
            <Grid xs={12} lg={11} mt={2}>
            <FormGroup sx={{width:"100%"}} className="mb-4" controlId="stressLevel">
              <FormLabel aria-label='Average Daily Stress Level' column sm={3} lg={2} htmlFor="stressLevel">Average Daily Stress Level</FormLabel>
              <CustomSlider
                id="stressLevel"
                aria-label="stressLevel"
                value={profileDetails.stressLevel}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                name='stressLevel'
                min={0}
                max={5}
                step="1"
                marks
              />
            </FormGroup>
            </Grid>
          </Grid>
          <Grid xs={12} lg={11} display={'flex'}>
            <Button variant='primary' type="submit" disabled={!hasChanges || loading}>
             <Typography variant='sb16SemiBold'>{loading ? 'Updating...' : 'Update Profile'}</Typography>
            </Button>
          </Grid>
          </Grid>
          <Snackbar
            sx={{}}
            open={openSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            message={snackbarMessage}
            action={
              <Button color="inherit" onClick={() => setOpenSnackbar(false)}>
                Close
              </Button>
            }
          >
            <Alert sx={{fontSize:"20px", padding:"10px"}} onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Grid alignSelf={'center'} container alignItems={'center'} justifyContent={'center'} ref={premiumSectionRef} id="premium_subscription" sx={{ mt: 3, mb: 4 }}>
            {/* change all this and it only shows tier: premium/free and premiumstatus if premium, then a button to try premioum/manage subscription */}
            <Grid width={"100%"} bgcolor={"white"} borderRadius={"10px"} border={"1px solid #E3DBD5"} boxShadow={"0px 5px 10px #EFE5DE"}>
              <Grid display={"flex"} gap={"15px"} alignItems={'center'} color={'white'} bgcolor={"#5C83BF"} padding={"10px"} borderRadius={"10px 10px 0px 0px"}>
                <Typography variant='sb24SemiBold'>Current Plan</Typography>
                <Box bgcolor={"rgb(0 255 44 / 33%)"} width={"fit-content"} padding={"7px 10px 7px 10px"} border={"2px solid white"} borderRadius={"40px"}>
                  <Typography color={"white"} variant='sb20Bold'>{tier === "free" ? "Free" : "Premium"}</Typography>
                </Box>
              </Grid>
              <Grid display={'flex'} flexDirection={'column'} gap={"40px"} padding={"20px 30px"}>
              <Grid display={'flex'} flexDirection={"column"} gap={"20px"}>
            <Typography variant='sb20Bold'>Your plan includes:</Typography>
            <Grid display={'flex'} flexDirection={'column'} gap={"5px"}>
              <Grid display={'flex'} alignItems="center" spacing={1} gap={"5px"}>
                <CheckIcon sx={{color:"rgb(92, 131, 191)"}}/>
                <Typography variant='sb20Medium'>5 Minutes Per Session</Typography>
              </Grid>
              <Grid display={'flex'} alignItems="center" spacing={1} gap={"5px"}>
                <CheckIcon sx={{color:"rgb(92, 131, 191)"}}/>
                <Typography variant='sb20Medium'>1 Session Per day</Typography>
              </Grid>
              <Grid display={'flex'} alignItems="center" spacing={1} gap={"5px"}>
                <CheckIcon sx={{color:"rgb(92, 131, 191)"}}/>
                <Typography variant='sb20Medium'>Limited access To Self-Improvement Content</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid display={'flex'} flexDirection={"column"} gap={"20px"}>
            <Typography variant='sb20Bold'>With Premium:</Typography>
            <Grid display={'flex'} flexDirection={'column'} gap={"5px"}>
              <Grid display={'flex'} alignItems="center" spacing={1} gap={"5px"}>
                <RadioButtonUncheckedIcon sx={{color:"rgb(92, 131, 191)"}}/>
                <Typography variant='sb20Medium'>10 Minutes Per Session</Typography>
              </Grid>
              <Grid display={'flex'} alignItems="center" spacing={1} gap={"5px"}>
                <RadioButtonUncheckedIcon sx={{color:"rgb(92, 131, 191)"}}/>
                <Typography variant='sb20Medium'>Unlimited Sessions Per day*</Typography>
              </Grid>
              <Grid display={'flex'} alignItems="center" spacing={1} gap={"5px"}>
                <RadioButtonUncheckedIcon sx={{color:"rgb(92, 131, 191)"}}/>
                <Typography variant='sb20Medium'>Full Access To Self-Improvement Content</Typography>
              </Grid>
              <Grid display={'flex'} alignItems="center" spacing={1}>
                <Typography variant='sb16Medium' sx={{padding: '4px'}}>*<em>Limited to maximum 10 minutes</em></Typography>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
          <div className="d-flex justify-content-center mb-4">
          {role !== "admin" &&
            <Button 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave} 
              sx={{
                backgroundColor:"#008000", 
                padding:"10px 26px 10px 22px", 
                border: "1.5px solid #008000", 
                "&:hover": {border: "1.5px solid #4CBB17", backgroundColor:"#4CBB17"}
              }} 
                type="button" 
                disabled={premiumLoading} 
                onClick={handlePremium}
            >
              <Typography color={'white'} variant='sb16SemiBold'>
                {premiumLoading ? 'redirecting...' :
                  tier === "free" && !premium_status ? 'Upgrade to Premium' :
                    tier === "free" && premium_status ? 'Fix Subscription' :
                      tier === "premium" && 'Manage Subscription'}
              </Typography>
            </Button>
          }
          </div>
          </Grid>
          </Grid>
        </FormControl>
        </form>
        </Grid>
        <Grid item xs={12} mb={5}>
          
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

const UserProfile = () => (
  <Elements stripe={stripePromise}>
    <UserProfileComponent />
  </Elements>
)

export default UserProfile;
