import React, { useEffect, useState } from "react";
import { Card, Row , Col, Container, Toast} from "react-bootstrap";
import { useUser } from "../contexts/UserContext";
import { URL } from "../Helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import "./AdminView.scss"
import "./style.scss";
import { Button, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Popover, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMediaQuery } from 'react-responsive';

const AddCompanyUsers = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedDepartment, setUpdatedDepartment] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [editDeparment, setEditDepartment] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${URL}/get_users_sessions_by_company`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.accessToken}`
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        setUsers(data.users_list);  
        console.log(data.users_list)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleAddUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await fetch(`${URL}/create_user_for_company`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({ email, department })
      });
      const data = await response.json();
      if (response.ok) {
        setUsers([...users, { email, department, id: data.userId }]);
        setEmail("");
        setDepartment("");
        setToastMessage("Employee added successfully!");
        setToastType("success");
        setShowToast(true);

      } else {
        setToastMessage("Error adding employee");
        setToastType("error");
        setShowToast(true);
      }
    } catch (error) {
      setToastMessage("Error adding employee");
      setToastType("error");
      setShowToast(true);
    }
  
    setLoading(false);
  };

  const handleEditUser = (userId) => {
    setEditDepartment(true)
    const userToEdit = users.find(user => user.id === userId);
    if (userToEdit) {
      setEditingUserId(userId);
      setUpdatedEmail(userToEdit.email);
      setUpdatedDepartment(userToEdit.department);
    }
  };

const handleUpdateUser = async () => {
  if (!editingUserId) return;

  setLoading(true);

  try {
    const response = await fetch(`${URL}/update_company_user/${editingUserId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.accessToken}`
      },
      body: JSON.stringify({ email: updatedEmail, department: updatedDepartment })
    });
    
    if (response.ok) {
      setUsers(prevUsers => prevUsers.map(user => 
        user.id === editingUserId ? { ...user, email: updatedEmail, department: updatedDepartment } : user
      ));
      setEditingUserId(null);
      setUpdatedEmail("");
      setUpdatedDepartment("");
      setToastMessage("Employee updated successfully!");
      setToastType("success");
      setShowToast(true);
    } else {
      setToastMessage("Error updating employee");
      setToastType("error");
      setShowToast(true);
    }
  } catch (error) {
        setToastMessage("Error updating employee");
        setToastType("error");
        setShowToast(true);
  }
  setLoading(false);
};


  const handleDeleteUser = async (userId) => {
    setLoading(true);

    try {
      const response = await fetch(`${URL}/delete_company_user/${userId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${user.accessToken}`
        }
      });
  
      if (response.ok) {
        setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
        setToastMessage("Employee deleted successfully");
        setToastType("success");
        setShowToast(true);
      } else {
        setToastMessage("Error deleting employee");
        setToastType("error");
        setShowToast(true);
    }
    } catch (error) {
        setToastMessage("Error deleting employee");
        setToastType("error");
        setShowToast(true);
    }
    setLoading(false);
  };

  const MoreOptionsPopover = ({ userId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
      <>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Button onClick={() => handleEditUser(userId)} sx={{padding:"6px 16px 6px 0px", width:"fit-content"}}><Typography color={"black"}>Edit</Typography></Button>
          <Button onClick={() => handleDeleteUser(userId)}><Typography color={"black"}>Delete</Typography></Button>
          <Button><Typography color={"black"}>Suspend</Typography></Button>
        </Popover>
      </>
    );
  };  
  
  return (
    <div className="prevent_overflow">
      <Navbar/>
      <Toast 
        onClose={() => setShowToast(false)} 
        show={showToast} delay={3000} 
        className={`custom_toast ${toastType === 'success' ? 'toast-success' : 'toast-error'}`}
        autohide  
      >
        <h6 className="text-white mt-2">{toastMessage}</h6>
      </Toast>
      <Grid display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h32Medium" color={"#4C6481"}>Employees Management</Typography>
        <Grid container mt={4} justifyContent={"center"} gap={"40px"}>
          <Grid md={8} xs={12} sm={11} lg={6} bgcolor={"white"} borderRadius={"10px"} border={"1px solid #E3DBD5"}>
              <Grid bgcolor={"#5C83BF"} padding={"16px 24px 16px 24px"} borderRadius={"10px 10px 0px 0px"}>
                <Typography variant="sb24Bold" color={"white"}>Add New Employee</Typography>
              </Grid>
              <Grid display={"flex"} justifyContent={"center"} padding={"42px"} bgcolor={"white"} borderRadius={"0px 0px 10px 10px"}>
                <form onSubmit={handleAddUser} style={{width:isSmallScreen ? "80%" : "50%", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="emailInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Email address"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="departmentInput"
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      required
                      placeholder="Department"
                    />
                  </div>
                  <Button variant="primary" type="submit">
                    <FontAwesomeIcon icon="fa-solid fa-plus" size="lg"/>
                    <Typography variant="sb16Bolder">Add Employee</Typography>
                  </Button>
                </form>
              </Grid>
            </Grid>
            <Grid md={8} xs={12} sm={11} lg={6} bgcolor={"white"} borderRadius={"10px"} border={"1px solid #E3DBD5"}>
              <Grid bgcolor={"#5C83BF"} padding={"16px 24px 16px 24px"} borderRadius={"10px 10px 0px 0px"}>
                <Typography variant="sb24Bold" color={"white"}>Employees</Typography>
              </Grid>
              <Grid bgcolor={"white"} padding={"20px"} borderRadius={"0px 0px 10px 10px"}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: 20, fontWeight: 700, color:"#4C6EA2" }}>Email</TableCell>
                        <TableCell style={{ fontSize: 20, fontWeight: 700, color:"#4C6EA2" }}>Department</TableCell>
                        <TableCell style={{ fontSize: 20, fontWeight: 700, color:"#4C6EA2" }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow key={user.id}>
                          <TableCell style={{ fontSize: 16, fontWeight: 600}}>
                            {editingUserId === user.id ? (
                              <Grid mb={4.5}>
                              <input
                                  type="email"
                                  className="form-control mb-2"
                                  value={updatedEmail}
                                  onChange={(e) => setUpdatedEmail(e.target.value)}
                                />
                              </Grid>
                              )  : (
                                user.email
                            )}
                          </TableCell>
                          <TableCell style={{ fontSize: 16, fontWeight: 600}}>
                          {editingUserId === user.id ? (
                              <div className="mt-3">
                                <input
                                  type="text"
                                  className="form-control mb-2"
                                  value={updatedDepartment}
                                  onChange={(e) => setUpdatedDepartment(e.target.value)}
                                />
                              
                                <div className="d-flex justify-content-end gap-2 mt-3">
                                  <Button variant="primary" onClick={handleUpdateUser}>
                                    <Typography variant="sb16Bolder">Update</Typography>
                                  </Button>
                                  <Button variant="secondary"                                     
                                    sx={{
                                      '&:hover': {
                                        color: 'white !important',
                                        backgroundColor: '#919191 !important'
                                      },
                                      color: '#919191 !important',
                                      border: '1px solid #919191'
                                    }} onClick={() => setEditingUserId(null)}><Typography variant="sb16Bolder">Cancel</Typography>
                                  </Button>
                                </div>
                              </div>
                              )  : (
                                user.department
                            )}
                          </TableCell>
                          <TableCell>
                            <MoreOptionsPopover userId={user.id} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
      <Footer/>
    </div>
  );
};

export default AddCompanyUsers;
