import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  ErrorBoundary,
  FallbackProps,
  useErrorBoundary,
} from "react-error-boundary";
import './style.scss';
import Navbar from './shared/Navbar';

export default function GeneralError({ error, resetErrorBoundary }) {

  return (
    <>
      <Navbar />

      <div className="d-flex justify-content-center prevent_overflow" style={{ position: "absolute" }}>

        <Row className="justify-content-center text-center align-items-center">
          <Col className="px-5">
            <h1>Something went wrong</h1>
            <p className="mt-3">
              {error.message}
            </p>
            <button className="blue_btn" onClick={resetErrorBoundary}>
              Try again
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
};

