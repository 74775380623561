import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ topicPercentages }) => {
  // Convert object to array of objects
  const topicDataArray = Object.entries(topicPercentages).map(([topic, value]) => ({ topic, value: parseFloat(value) }));

  // Sort topics by value (percentage)
  const sortedTopicsData = topicDataArray.sort((a, b) => b.value - a.value);

  // Extract top topic
  const topTopic = sortedTopicsData[0] || { topic: 'No data', value: 0 };

  const customStyles = `
  .apexcharts-text {
    fill: #4C6EA2 !important;
  }
`;



  const chartOptions = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '16px',
          },
          value: {
            fontSize: '22px',
          },
          total: {
            show: true,
            label: topTopic.topic,
            formatter: function () {
              return `${topTopic.value.toFixed(2)}%`; // Display percentage with 2 decimal places
            },
          },
        },
      },
    },
    labels: sortedTopicsData.map(topic => topic.topic),
  };

  const seriesForChart = sortedTopicsData.map(topic => topic.value);

  return (
    <div className="chart-container">
      {Array.isArray(seriesForChart) && seriesForChart.length > 0 ? (
        <div className="apex-chart" id="apex-chart2">
          <style>{customStyles}</style>
          <ReactApexChart
            options={chartOptions}
            series={seriesForChart}
            type="radialBar"
            height={350}
          />
        </div>
      ) : (
        <div className="apex-chart" id="chart2" style={{ marginTop: '5rem' }}>
          <h4>There is no data available to display.</h4>
        </div>
      )}
    </div>
  );
};

export default ApexChart;
