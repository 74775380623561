import React, { useState, useEffect } from 'react';
import { Container, InputGroup, FormControl, Card, ListGroup, Row, Col } from 'react-bootstrap';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import './style.scss';

const SOS = () => {
  const [hotlines, setHotlines] = useState({});
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Load the hotlines JSON data. Assuming the file is in the public directory.
    fetch('/suicide-hotlines.json')
      .then((response) => response.json())
      .then((data) => setHotlines(data));
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredCountries([]);
    } else {
      const matchedCountries = Object.keys(hotlines).filter((country) =>
        country.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCountries(matchedCountries);
    }
  }, [searchTerm, hotlines]);

  return (
    <div className="prevent_overflow">
    <Navbar />
      <Container className="sos-container content mt-5">
        <h1 className="text-center mb-4">Emergency Hotlines</h1>
        <p className="text-center mb-4 mx-3">
          If you are in crisis or experiencing suicidal thoughts, please search for your country below to find immediate help.
        </p>
        <Row className="d-flex justify-content-center mx-3">
          <Col xl={7} lg={8}>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Search for your country..."
              aria-label="Search for your country"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
          {filteredCountries.length > 0 && (
          <ListGroup>
            {filteredCountries.map((country) => (
              <ListGroup.Item action key={country} onClick={() => setSearchTerm(country)}>
                {country}
              </ListGroup.Item>
            ))}
          </ListGroup>
          )}
          {searchTerm && hotlines[searchTerm] && (
            <Card>
              <Card.Header>Hotlines for {searchTerm}</Card.Header>
              <Card.Body>
                {hotlines[searchTerm].parsed_batches.map((batch, index) => (
                  <div key={index} className="my-2">
                    <Card.Title>{batch.description}</Card.Title>
                    {batch.phones && batch.phones.length > 0 && (
                      <ListGroup variant="flush">
                        {batch.phones.map((phone, phoneIndex) => (
                          <ListGroup.Item key={phoneIndex}>
                            <a href={`tel:${phone}`}>{phone}</a>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                    {batch.link && (
                      <a href={batch.link.url} target="_blank" rel="noopener noreferrer">
                        {batch.link.text}
                      </a>
                    )}
                  </div>
                ))}
              </Card.Body>
            </Card>
          )}
          </Col>
         
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default SOS;