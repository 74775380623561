// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { doc, getDoc,getFirestore,onSnapshot, connectFirestoreEmulator, getDocs,query,collection,where } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth' // Import the Firebase authentication module
import { listAll as firebaseListAll, getStorage, ref, getDownloadURL, connectStorageEmulator  } from "firebase/storage";




const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
}

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)
let analytics;

const auth = getAuth(firebase) // Initialize the Firebase authentication module
const db = getFirestore(firebase);
const storage = getStorage();
const listAll = firebaseListAll;

if (window.location.hostname === 'localhost') {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  //not working in backend so using production
  // connectStorageEmulator(storage, "localhost", 9199); 
  // console.log('using emulator',auth, storage,firebase)
}
else {
  analytics = getAnalytics(firebase);
}


export { firebase, auth, db,onSnapshot, doc, getDoc, ref, getDownloadURL, listAll, storage, getDocs,collection,query,where} // Export the Firebase authentication module
