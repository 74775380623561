import { CircularProgress, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { faFaceSmile as smileIcon, faFaceRollingEyes as rollingEyesIcon, faFaceSurprise as supriseIcon, faFaceFrown as sadIcon, faFaceGrinHearts as heartIcon } from '@fortawesome/free-solid-svg-icons';
import './SessionResponseSpinner.scss';

const SessionResponseSpinner = () => {
  const [isSmile, setIsSmile] = useState(true);
  const [icons, setIcons] = useState([smileIcon, rollingEyesIcon, heartIcon, supriseIcon, sadIcon]); 
  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  useEffect(() => {
    const intervalTrue = setInterval(() => {
      setIsSmile(false);
    }, 2000);
  
    const intervalFalse = setInterval(() => {
      setIsSmile(true);
    }, 4000);
  

    return () => {
      clearInterval(intervalTrue);
      clearInterval(intervalFalse);
    };
  }, []);
  
  useEffect(() => {
    const nextIconIndex = (currentIconIndex + 1) % icons.length;
    setCurrentIconIndex(nextIconIndex);
  }, [isSmile]);

  return (
    <Grid justifyContent={'center'} display={'flex'} alignItems={'center'} mt={1} className="bigger_height">
      <Grid textAlign={'center'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <div style={{ position: 'relative', width: '70px', height: '70px' }}>
          <CircularProgress sx={{ color: "#4C6EA2" }} size={80} />
          <Grid position={'absolute'} bottom={"14px"} left={"24px"} className="session-loading">
          <FontAwesomeIcon icon={icons[currentIconIndex]} size='2xl' style={{color:"#4C6EA2"}} className={isSmile ? 'fade-in-out' : 'fade-out-in'} />
          </Grid>
        </div>
        <Typography variant='sb24Bold' color={"#4C6EA2"} mt={5} mb={2}>Analyzing your communication styles</Typography>
        <Typography variant='body1SemiBold' color={"#8D8885"}>Patience young PokaMinder, good things come to those who wait! <br />Please do not refresh this page or we will have to start over. <br /> We promise this will only take a minute or two!</Typography>
      </Grid>
    </Grid>
  );
};

export default SessionResponseSpinner;
