import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useUser } from '../contexts/UserContext';
import { URL } from '../Helpers';
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import LoadingSpinner from './shared/LoadingSpinner';
import { Typography, Button, Grid, SvgIcon, Card, Badge, Link } from "@mui/material";
import { ReactComponent as CalendarIcon } from '../data/calendar-add.svg';
import './ViewTherapistProfile.scss';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import moment from 'moment'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import { Elements } from '@stripe/react-stripe-js';
import "./BookTherapy.scss";
import './style.scss';
import { styled } from '@mui/system';

const stripePromise = loadStripe(process.env.REACT_APP_stripeKey);

const CustomPicker = styled(DatePicker)(() => ({
  '& .react-datepicker-popper': {
    // Calculate color based on value
    // top:"500px",
    // right: "400px"
    // transform: "translate3d(710px, 256px, 0px) !important"
  },
}));

const ViewTherapistProfile = () => {
  const { id } = useParams();
  const { user } = useUser();
  const [therapistInfo, setTherapistInfo] = useState();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const stripe = useStripe();
  const [displayPicker, setDisplayPicker] = useState(false)
  const [selectedTherapistInfo, setSelectedTherapistInfo] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [showDataShareModal, setShowDataShareModal] = useState(false);
  const [consentGiven, setConsentGiven] = useState(true);
  const [dateGroups, setDateGroups] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [loading, setLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState(null);
  const [displayTimePicker, setDisplayTimePicker] = useState(false)
  const [showDateTime, setShowDateTime] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([])
  const [PickerLoading, setPickerLoading] = useState(null)
  const [times, setTimes] = useState([])
  const [formattedSlots, setFormattedSlots] = useState([])
  const [timeSlotId, setTimeSlotId] = useState('');
  const [firstButton, setFirstButton] = useState(false);
  const [secondButton, setSecondButton] = useState(false);
  const location = useLocation()

  useEffect(() => {
    const fetchTherapist = async () => {
      try {
        // Fetch the therapist info and timeslots using the API call to the Flask backend
        const response = await fetch(`${URL}/therapists_timeslots/${id}`,
          {
            method: "GET",
            headers: {
              'Content-Type': 'application/json'
              // 'Authorization': `Bearer ${user.accessToken}`
            },
          });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setTimes(data.availableSlots.map(slot => slot.time))

        if (data.therapistInfo) {
          setTherapistInfo(data.therapistInfo);
        } else {
          console.error("Therapist document does not exist.");
        }

        if (data.availableSlots) {
          // const formattedSlots = data.availableSlots.map(slot => {
          //   // Use the formatTimeslot function to format the date
          //   return {
          //     ...slot,
          //     timestamp: formatTimeslot(new Date(slot.time)) // Assuming slot.time is in the correct format to be converted to Date
          //   };
          // });
          setFormattedSlots(data.availableSlots.map(slot => ({
            ...slot,
            timestamp: formatTimeslot(new Date(slot.time))
          })));

          // setDateGroups(chunk(formattedSlots, 3)); // Assumes `chunk` function is defined elsewhere
        }

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error("Error fetching therapist information and time slots:", error);
      }
    };
    //check if there was a booking in progress and user cancelled
    const unbookTimeslot = async (therapistID, timeslotID) => {
      try {
        // Fetch the therapist info and timeslots using the API call to the Flask backend
        const response = await fetch(`${URL}/unbook_timeslot`,
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.accessToken}`
            },
            body: JSON.stringify({
              therapistId: therapistID,
              timeslotId: timeslotID,
            })
          });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching therapist information and time slots:", error);
      }
    };

    const checkoutInProgress = sessionStorage.getItem('checkoutInProgress')
    //you must compare with string 'true', since its saved as string
    if (checkoutInProgress === 'true') {
      sessionStorage.removeItem('checkoutInProgress')
      const savedTimeslotID = sessionStorage.getItem('timeslotId')
      const savedTherapistID = sessionStorage.getItem('therapistId')
      //unbook timelsot
      if (!savedTherapistID || !savedTimeslotID)
        console.error("Error while unbooking timeslot, timeslotId or therapistId not found")
      console.log('unbooking timelsots', savedTherapistID, savedTimeslotID)
      unbookTimeslot(savedTherapistID, savedTimeslotID).then(fetchTherapist)
      //remove session storages
      sessionStorage.removeItem('timeslotId')
      sessionStorage.removeItem('therapistId')
    }
    else
      // if (user)
      fetchTherapist();

  }, [id, user]);

  const handleBookNow = async () => {
    try {
      if (!user) return navigate('/auth', { state: { from: location }, replace: true })
    
        if (selectedDate) {
        setSelectedDate(null)
      }

      setPickerLoading(true)
      setTimeout(() => {
        setDisplayTimePicker(true);
        setShowDateTime(false);
        setPickerLoading(false)
        setDisplayPicker(true);
      });

      setAvailableTimes(times);

    } catch (error) {
      console.error("Error fetching therapist information and time slots:", error);
    }
  };

  const handleBooking = async () => {
    try {

      const combinedDateTime = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTime.hours(),
        selectedTime.minutes(),
        0,
      );
      const utcDateTime = new Date(Date.UTC(
        combinedDateTime.getFullYear(),
        combinedDateTime.getMonth(),
        combinedDateTime.getDate(),
        combinedDateTime.getHours(),
        combinedDateTime.getMinutes()
      ));

      const combinedISO = utcDateTime.toISOString().slice(0, -8);

      const matchingSlot = formattedSlots.find(slot => {
        const slotISO = new Date(slot.time).toISOString().slice(0, -8); // Truncate seconds  
        return slotISO === combinedISO;
      });

      console.log(matchingSlot)
      if (matchingSlot) {
        setTimeSlotId(matchingSlot.id);
      } else {
        console.log("No matching slot found.");
      }
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  }

  const formatTimeslot = (dateObj) => {
    // Format the date
    const dateFormatter = new Intl.DateTimeFormat("en-UK", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const formattedDate = dateFormatter.format(dateObj);

    // Format the time
    const timeFormatter = new Intl.DateTimeFormat("en-UK", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
      timezone: "CET"
    });
    const formattedTime = timeFormatter.format(dateObj);

    return {
      date: formattedDate,
      time: formattedTime,
    };
  }
  const handlePay = async (timeSlotId, therapistID, dataShareFlag) => {
    setLoading(true)
    // Get the user's location
    navigator.geolocation.getCurrentPosition(async (position) => {
      const location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      //save timeslot and therapistID in session storage in case of errors to revert booking
      sessionStorage.setItem('timeslotId', timeSlotId)
      sessionStorage.setItem('therapistId', therapistID)
      sessionStorage.setItem('checkoutInProgress', true)
      // Include the location in the POST request
      const response = await fetch(`${URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({
          therapistId: therapistID,
          timeslotId: timeSlotId,
          location: location,
          dataShareConsent: dataShareFlag
        })
      });

      const { sessionId, sessionURL, error } = await response.json();
      if (!sessionURL) {
        setLoading(false)
        console.error(`Failed to create checkout session: ${error}`);
        //remove session storages
        sessionStorage.removeItem('timeslotId')
        sessionStorage.removeItem('therapistId')
        return;
      }


      //ELIF comment
      console.log(sessionURL)
      // window.location.href = sessionURL
      const stripeError = await stripe.redirectToCheckout({ sessionId: sessionId });
      if (stripeError) {
        console.warn('Error:', stripeError);
      }
      //ELIF end comment
      //ELIF uncomment
      // navigate(`/book-success/${therapistID}/${timeslot.id}/ELIF`)
      //ELIF end uncomment
    }, (error) => {
      setLoading(false)
      console.error(`Geolocation error: ${error}`);
      setToastMessage("Please Allow Location permission to continue");
      setToastType('error');
      setShowToast(true);
    });
  };

  const toggleConsent = () => {
    setConsentGiven(!consentGiven);
  };

  const onPay = () => {
    handlePay(timeSlotId, id, consentGiven);
    setShowDataShareModal(false);
  };
  // const handleBookNow = () => {
  //   navigate(`/book-therapist/${id}`);
  // };

  const handleDateChange = (date) => {
    // setDisplayTimePicker(true)
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleTimeChange = (value) => {
    setSelectedTime(value);
  };

  const filteredAvailableDates = availableTimes.map(time => moment.utc(time, 'ddd, DD MMM YYYY HH:mm:ss [GMT]'));

  const isDateDisabled = (date) => {
    const dateStr = moment.utc(date).startOf('day');
    const nextDay = dateStr.clone().add(1, 'day'); // Get the next day
    return filteredAvailableDates.some(availableDate => availableDate.isSame(nextDay, 'day'));
  };

  const disabledHours = () => {
    if (!selectedDate) return [];

    const selectedDateStr = moment.utc(selectedDate).startOf('day');
    const nextDay = selectedDateStr.clone().add(1, 'day');

    const availableHours = availableTimes
      .filter(time => moment.utc(time).isSame(nextDay, 'day'))
      .map(time => moment.utc(time).hour());

    const allHours = Array.from({ length: 24 }, (_, i) => i);

    const disabledHours = allHours.filter(hour => !availableHours.includes(hour));

    return disabledHours;
  };



  const disabledMinutes = (selectedHour) => {
    if (!selectedDate) return [];

    const selectedDateStr = moment.utc(selectedDate).startOf('day').hour(selectedHour);
    const nextDay = moment.utc(selectedDate).startOf('day').add(1, 'day').hour(selectedHour);

    const availableMinutes = availableTimes
      .filter(time => moment.utc(time).isSame(nextDay, 'hour'))
      .map(time => moment.utc(time).minute());

    const allMinutes = Array.from({ length: 60 }, (_, i) => i);

    const disabledMinutes = allMinutes.filter(minute => !availableMinutes.includes(minute));

    return disabledMinutes;
  };


  return (
    <div className="prevent_overflow">
      <Navbar />
      {!therapistInfo ? (
        <LoadingSpinner className="bigger_height" />
      ) : (
        <>
          <Grid sx={{ pl: 4 }}>
            <Link onClick={() => navigate("/book-therapist")} sx={{ color: "primary.darkerBlue", textDecoration: 'none', cursor:"pointer" }}>
              <FontAwesomeIcon icon="fa-solid fa-arrow-left" size="lg" style={{ paddingRight: "5px", paddingBottom: "2px" }} />
              <Typography variant="sb24Bold" color="primary.darkerBlue">Therapists</Typography>
            </Link>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", my: 6 }}>
            <img src={therapistInfo?.img_url} alt={therapistInfo?.name} className="therapist-image ms-3" />
            <div className="ms-3">
              <div className="profile_text">
                <Typography variant={isSmallScreen ? 'h32Bold' : 'h42Bold'} color="otherColors.mainBodyDark"> Schedule an appointment with
                  <Typography variant={isSmallScreen ? 'h32Bold' : 'h42Bold'} color="primary.darkerBlue"> {therapistInfo?.name}</Typography>
                </Typography>
              </div>
              <Button variant="primary" onClick={() => { handleBookNow(); setSecondButton(false); setFirstButton(true) }} sx={{ mt: 2 }}>
                <SvgIcon component={CalendarIcon} width="10px" height="10px" />
                <Typography variant="sb16Bolder">{showDateTime === true ? ("Book Again") : ("Book Now")}</Typography>
              </Button>
              {displayPicker && !selectedDate && firstButton && (
                <CustomPicker
                  open={displayPicker}
                  selected={selectedDate}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  filterDate={isDateDisabled}
                  hideDisabledOptions={true}
                  allowEmpty={false}
                  placeholderText={'Select Date'}
                  onClickOutside={() => {
                    if (displayPicker) {
                      setDisplayPicker(false);
                    }
                  }}
                />
              )}
              {displayTimePicker && !showDateTime && selectedDate && firstButton && (
                <Grid style={{ position: 'absolute' }}>
                  {selectedTime && (
                    <Button
                      sx={{ width: "fit-content", '&:hover': { border: "1px solid white !important" } }}
                      variant="secondary"
                      onClick={() => { handleBooking(); setShowDateTime(true) }}
                      style={{ position: "absolute", zIndex: 9999, top: "212px", left: "155px" }}
                    >
                      <Typography variant="sb16Bolder">Book</Typography>
                    </Button>
                  )}
                  <button
                    onClick={() => {
                      setDisplayTimePicker(false);
                    }}
                    style={{ position: "absolute", zIndex: 9999, top: "10px", left: "222px", border: "none", backgroundColor: "transparent" }}
                  >
                    <Typography variant="sb16Bolder" color={"white"}>X</Typography>
                  </button>
                  <TimePicker
                    open={selectedDate}
                    showSecond={false}
                    value={selectedTime}
                    onChange={handleTimeChange}
                    format="HH:mm"
                    hideDisabledOptions={true}
                    allowEmpty={false}
                    placeholder="Select Time"
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    onClickOutside={() => {
                      if (displayTimePicker) {
                        setDisplayTimePicker(false);
                      }
                    }}
                    style={{ position: 'absolute', zIndex: 1, marginTop: "12px" }}
                  />
                </Grid>
              )}
            </div>
          </Grid>
          <Grid container flexDirection={"column"} justifyContent={'center'} alignItems={'center'} sx={{ display: "flex", justifyContent: "center", mb: 8, mt: 3 }}>
            {showDateTime && firstButton && (
              <Grid xs={12} xl={7} lg={7} md={9} my={4} display={'flex'} flexDirection={'column'} alignSelf={'center'} height={"fit-content"} textAlign={'center'} alignItems={'center'} justifyContent={'center'}>
                <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} bgcolor={'white'} borderRadius={"10px"} border={"1px solid #E3DBD5"} boxShadow={"0px 5px 10px #E9E2DD"} width={"fit-content"} height={"fit-content"} textAlign={'center'} padding={"24px"} gap={"10px"}>
                  <Grid bgcolor={"#0dff0012"} padding={"5px"} justifyContent={'center'} display={'flex'} alignItems={'center'} gap={"10px"}>
                    <Typography variant='sb20SemiBold'>Selecting date and time:</Typography>
                    <Typography variant='sb20SemiBold' color={"#62C294"}>{selectedDate && selectedDate.toDateString()}</Typography>
                    <Typography variant='sb20SemiBold' color={"#62C294"}>-</Typography>
                    <Typography variant='sb20SemiBold' color={"#62C294"}>{selectedTime.format('HH:mm')}</Typography>
                  </Grid>
                  <Modal style={{ borderRadius: "10px", border: "1px solid #E3DBD5" }} show={showDataShareModal} onHide={() => setShowDataShareModal(false)} centered>
                    <Grid>
                      <Grid bgcolor={"#5C83BF"} padding={"16px"}>
                        <Typography color={"white"} variant='sb24Bold'>Share Insights?</Typography>
                      </Grid>
                      <Grid padding={"24px"} textAlign={'center'}>
                        <Typography variant='sb16Bold'>Allow your therapist to access your sessions' insights, enhancing therapy quality. Your confidentiality is always protected.</Typography>
                        <Grid my={2} justifyContent={'center'} alignItems={'center'} gap={"2px"} display={'flex'}>
                          <Checkbox id="shareData" checked={consentGiven} onChange={toggleConsent} />
                          <Typography variant='sb16Bold'>I allow my therapist to see my session insights.</Typography>
                        </Grid>
                        <Grid className="modal-footer-custom">
                          <Button sx={{ display: "flex", alignItem: "center" }} variant="primary" onClick={onPay}>
                            {/* <SvgIcon component={ArrowForward}/> */}
                            <Typography variant="sb16Bolder">Continue</Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Modal>
                  <Typography my={1} variant='sb24SemiBold'>Do you want to proceed with checkout?</Typography>
                  <Button variant="primary" onClick={() => setShowDataShareModal(true)}>
                    <Typography variant="sb16Bolder">Pay Now</Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid width={"100%"} display={'flex'} justifyContent={'center'} >
              <Grid item xs={11} sm={9} md={8} lg={7} >
                <Card className="therapist-info_card">
                  {therapistInfo.long_bio && (
                    <div>
                    <Typography variant="sb20SemiBold" color="primary.darkerBlue">About</Typography>
                    <div className="horizontal-line" style={{ background: "#E5E5E5", marginBottom: "15px" }} />
                    <Typography variant="sb20SemiBold" color="otherColors.mainBodyDark" sx={{ pt: 2 }}>{therapistInfo?.long_bio}</Typography>
                  </div>
                  )}
                  {therapistInfo.therapist_approach && (
                    <div style={{ marginTop: "20px" }}>
                    <Typography variant="sb20SemiBold" color="primary.darkerBlue">Approach</Typography>
                    <div className="horizontal-line" style={{ background: "#E5E5E5", marginBottom: "15px" }} />
                    <Typography variant="sb20SemiBold" color="otherColors.mainBodyDark" sx={{ pt: 2 }}>{therapistInfo?.therapist_approach}</Typography>
                    </div>
                  )}
                  {therapistInfo.educations && (
                    <div style={{ marginTop: "20px" }}>
                    <Typography variant="sb20SemiBold" color="primary.darkerBlue">Education</Typography>
                    <div className="horizontal-line" style={{ background: "#E5E5E5", marginBottom: "15px" }} />
                    {therapistInfo && therapistInfo.educations?.map((education, index) => (
                      <div
                        key={index}
                        className="info-item mt-2"
                      >
                        <Typography variant="sb20SemiBold" color="otherColors.mainBodyDark" sx={{ pt: 2 }}>{education}</Typography>
                      </div>
                    ))}
                    </div>
                  )}
                  {therapistInfo.experiences && (
                    <div style={{ marginTop: "20px" }}>
                    <Typography variant="sb20SemiBold" color="primary.darkerBlue">Experience</Typography>
                    <div className="horizontal-line" style={{ background: "#E5E5E5", marginBottom: "15px" }} />
                    {therapistInfo && therapistInfo.experiences?.map((experience, index) => (
                      <div
                        key={index}
                        className="info-item mt-2"
                      >
                        <Typography variant="sb20SemiBold" color="otherColors.mainBodyDark" sx={{ pt: 2 }}>{experience}</Typography>
                      </div>
                    ))}
                    </div>
                  )}
                  {therapistInfo.areas && (
                    <div style={{ marginTop: "20px" }}>
                    <Typography variant="sb20SemiBold" color="primary.darkerBlue">Specialties</Typography>
                    <div className="horizontal-line" style={{ background: "#E5E5E5", marginBottom: "15px" }} />
                    {therapistInfo?.areas?.map((area, index) => (
                      <Badge key={index} className="custom-badge">
                        <Typography variant="sb16Bold" color="primary.darkerBlue">{area}</Typography>
                      </Badge>
                    ))}
                    </div>
                  )}
                  {therapistInfo.languages && (
                    <div style={{ marginTop: "20px" }}>
                    <Typography variant="sb20SemiBold" color="primary.darkerBlue">Spoken Languages</Typography>
                    <div className="horizontal-line" style={{ background: "#E5E5E5", marginBottom: "15px" }} />
                    <div className="d-flex flex-wrap py-2 badge-container">
                      {therapistInfo && therapistInfo.languages?.map((language, index) => (
                        <Badge key={index} className="custom-badge">
                          <Typography variant="sb16Bold" color="primary.darkerBlue">{language}</Typography>
                        </Badge>
                      ))}
                    </div>
                    </div>
                  )}
                  <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="primary" onClick={() => { handleBookNow(); setSecondButton(true); setFirstButton(false) }} sx={{ mt: 2 }}>
                      <SvgIcon component={CalendarIcon} width="10px" height="10px" />
                      <Typography variant="sb16Bolder">{showDateTime === true ? ("Book Again") : ("Book Now")}</Typography>
                    </Button>
                    {displayPicker && !selectedDate && secondButton && (
                      <CustomPicker
                        open={displayPicker}
                        selected={selectedDate}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        filterDate={isDateDisabled}
                        hideDisabledOptions={true}
                        allowEmpty={false}
                        placeholderText={'Select Date'}
                        onClickOutside={() => {
                          if (displayPicker) {
                            setDisplayPicker(false);
                          }
                        }}
                      />
                    )}
                    {displayTimePicker && !showDateTime && selectedDate && secondButton && (
                      <Grid style={{ position: 'absolute' }}>
                        {selectedTime && (
                          <Button
                            sx={{ width: "fit-content", '&:hover': { border: "1px solid white !important" } }}
                            variant="secondary"
                            onClick={() => { handleBooking(); setShowDateTime(true) }}
                            style={{ position: "absolute", zIndex: 9999, top: "212px", left: "155px" }}
                          >
                            <Typography variant="sb16Bolder">Book</Typography>
                          </Button>
                        )}

                        <TimePicker
                          open={selectedDate}
                          showSecond={false}
                          value={selectedTime}
                          onChange={handleTimeChange}
                          format="HH:mm"
                          hideDisabledOptions={true}
                          allowEmpty={false}
                          placeholder="Select Time"
                          disabledHours={disabledHours}
                          disabledMinutes={disabledMinutes}
                          onClickOutside={() => {
                            if (displayTimePicker) {
                              setDisplayTimePicker(false);
                            }
                          }}
                          style={{ position: 'absolute', zIndex: 1, marginTop: "12px" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            {showDateTime && secondButton && (
              <Grid xs={12} xl={7} lg={7} mt={3} md={9} display={'flex'} flexDirection={'column'} alignSelf={'center'} height={"fit-content"} textAlign={'center'} alignItems={'center'} justifyContent={'center'}>
                <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} bgcolor={'white'} borderRadius={"10px"} border={"1px solid #E3DBD5"} boxShadow={"0px 5px 10px #E9E2DD"} width={"fit-content"} height={"fit-content"} textAlign={'center'} padding={"24px"} gap={"10px"}>
                  <Grid bgcolor={"#0dff0012"} padding={"5px"} justifyContent={'center'} display={'flex'} alignItems={'center'} gap={"10px"}>
                    <Typography variant='sb20SemiBold'>Selecting date and time:</Typography>
                    <Typography variant='sb20SemiBold' color={"#62C294"}>{selectedDate && selectedDate.toDateString()}</Typography>
                    <Typography variant='sb20SemiBold' color={"#62C294"}>-</Typography>
                    <Typography variant='sb20SemiBold' color={"#62C294"}>{selectedTime.format('HH:mm')}</Typography>
                  </Grid>
                  <Modal style={{ borderRadius: "10px", border: "1px solid #E3DBD5" }} show={showDataShareModal} onHide={() => setShowDataShareModal(false)} centered>
                    <Grid>
                      <Grid bgcolor={"#5C83BF"} padding={"16px"}>
                        <Typography color={"white"} variant='sb24Bold'>Share Insights?</Typography>
                      </Grid>
                      <Grid padding={"24px"} textAlign={'center'}>
                        <Typography variant='sb16Bold'>Allow your therapist to access your sessions' insights, enhancing therapy quality. Your confidentiality is always protected.</Typography>
                        <Grid my={2} justifyContent={'center'} alignItems={'center'} gap={"2px"} display={'flex'}>
                          <Checkbox id="shareData" checked={consentGiven} onChange={toggleConsent} />
                          <Typography variant='sb16Bold'>I allow my therapist to see my session insights.</Typography>
                        </Grid>
                        <Grid className="modal-footer-custom">
                          <Button sx={{ display: "flex", alignItem: "center" }} variant="primary" onClick={onPay}>
                            {/* <SvgIcon component={ArrowForward}/> */}
                            <Typography variant="sb16Bolder">Continue</Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Modal>
                  <Typography my={1} variant='sb24SemiBold'>Do you want to proceed with checkout?</Typography>
                  <Button variant="primary" onClick={() => setShowDataShareModal(true)}>
                    <Typography variant="sb16Bolder">Pay Now</Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Footer />
    </div>
  );
};

const ViewTherapistProfile1 = () => (
  <Elements stripe={stripePromise}>
    <ViewTherapistProfile />
  </Elements>
);

export default ViewTherapistProfile1;
