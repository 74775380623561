import { Row, Col } from 'react-bootstrap';
import "../style.scss";
import { BeatLoader} from 'react-spinners';
import { CircularProgress, Grid, Typography } from '@mui/material';

const LoadingSpinner = ({ className, text }) => {
  return (
    <Row className={`justify-content-center align-items-center mt-4 ${className}`}>
      <Grid mb={4} display={'flex'} flexDirection={'column'} textAlign={'center'} justifyContent={'center'} alignItems={'center'} gap={"30px"}>
          <CircularProgress sx={{color:"#4C6EA2"}} size={70}/>
          <span className="visually-hidden">Loading...</span>
          <Typography variant='sb24Bold' color={"#4C6EA2"}>{text || "Loading... Please wait"}</Typography>
      </Grid>
    </Row>
  );
};

export default LoadingSpinner;
