
import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import Recorder from 'recorder-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faCamera } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Typography, SvgIcon, Box, LinearProgress, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ReactComponent as ArrowForward } from '../../data/arrow_forward.svg';
import { ReactComponent as ArrowBack } from '../../data/arrow_back.svg';
import Grid from '@mui/material/Unstable_Grid2';

const MediaTest = ({ onSuccess, onHide, show, value, onContinue, videoAccess, setVideoAccess }) => {
  const [micStatus, setMicStatus] = useState(false);
  const [camStatus, setCamStatus] = useState(false);
  const [amplitude, setAmplitude] = useState(0);
  const [canContinue, setCanContinue] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const webcamRef = React.useRef(null);
  const audioRecorderRef = useRef(null)
  const audioStreamRef = useRef(null)
  const navigate = useNavigate();
  const [selectedTopic, setSelectedTopic] = useState(null);

  const handleGoBack = () => {
    onHide(); // Call onHide to close the modal
  };

  useEffect(() => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const recorder = new Recorder(audioContext);
    audioRecorderRef.current = recorder
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        recorder.init(stream);
        recorder.start();
        // save stream
        audioStreamRef.current = stream;

        const analyser = audioContext.createAnalyser();
        audioContext.createMediaStreamSource(stream).connect(analyser);

        const data = new Uint8Array(analyser.frequencyBinCount);
        const update = () => {
          analyser.getByteFrequencyData(data);
          let value = 0;
          for (let i = 0; i < data.length; i++) {
            value += data[i];
          }
          const avgAmplitude = value / data.length;
          setAmplitude(avgAmplitude * 5); // Increased sensitivity
          setMicStatus(avgAmplitude > 0); // Adjusted threshold
          if (avgAmplitude > 0.01) {
            setCanContinue(true);
          }
          requestAnimationFrame(update);
        };
        update();
      })
      .catch((err) => {
        console.log('Error: ' + err);
      });
    return () => {
      // Cleanup function for audio stream and recorder
      if (audioRecorderRef.current) {
        audioRecorderRef.current.stop(); // Stop the recorder
        audioRecorderRef.current = null; // Release the recorder
      }
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => {
          track.stop(); // Stop all the tracks in the received media stream
        });
        audioStreamRef.current = null; // Release the media stream
      }
      // If you're using an AudioContext, close it to release resources
      if (audioContext.state === 'running') {
        audioContext.close()
      }
    }
  }, []);

  const handleCameraToggle = () => {
    if (videoAccess) {
      setOpenDialog(true);
    } else {
      setCamStatus(!camStatus);
      setVideoAccess(prev => !prev);
    }
  };

  const handleDialogClose = (confirm) => {
    if (confirm) {
      setCamStatus(!camStatus);
      setVideoAccess(false);
    }
    setOpenDialog(false);
  };

  return (
    <>

      <Grid gap={5} marginBottom={"50px"} container display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"} className="content">
        <Grid md={7} lg={6} xl={5} xs={10} border={"solid 1px #E3DBD5"} borderRadius={"10px"} justifyContent={"center"} sx={{ background: "#FFFFFF" }}>
          <Grid md={7} lg={6} xl={5} xs={10} padding={"16px 24px 16px 24px"} borderRadius={"10px 10px 0px 0px"} sx={{ background: "#5C83BF" }}>
            <Typography color={"white"} variant="sb24Bold">Instructions</Typography>
          </Grid>
          <Grid justifyContent={"center"} alignItems={"center"} gap={"24px"} padding={"42px 24px 36px 24px"} display={"flex"} flexDirection={"column"}>
            <Typography color={"#3F444B"} variant="sb16Bold" display={"flex"} alignItems={"center"} textAlign={"center"}>
              Please allow access to both your microphone and camera for better accuracy. You can say "myPoka" once to test your mic.
            </Typography>
            <Grid justifyContent={"center"} alignItems={"center"} gap={"24px"} padding={"42px 24px 36px 24px"} display={"flex"} flexDirection={"row"}>
              {videoAccess && (
                <Webcam
                  width={"50%"}
                  onUserMediaError={() => {
                    console.log("Camera access blocked");
                    setVideoAccess(false);
                  }}
                  audio={false}
                  style={{
                    borderRadius: "5%",
                    visibility: videoAccess ? "visible" : "hidden",
                  }}
                />
              )}
              <Button onClick={handleCameraToggle} variant="primary"
                sx={{
                  color: "white",
                }}>
                <FontAwesomeIcon icon="fa-solid fa-camera" />
                <Typography variant="sb16Bolder">{videoAccess ? "Disable Video" : "Enable Video"}</Typography>
              </Button>
            </Grid>

            <LinearProgress variant="determinate" value={amplitude} sx={{ width: { xs: "75%", sm: "45%" }, height: '8px', backgroundColor: '#E5E5E5', color: '#4C6EA2', borderRadius: "5px", marginBottom: "2%" }} />
            <Typography color={"#3F444B"} textAlign={"center"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <Typography variant="sb16Bold">After testing your mic and camera, you are ready to go.</Typography> <Typography variant="body1Medium"><em>Click the button to start your session!</em></Typography>
            </Typography>
            <Button onClick={onContinue} variant="primary"
              sx={{
                color: "white",
              }}>
              <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
              <Typography variant="sb16Bolder">Start Talking</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Disable Camera?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you would like to disable the camera? The communication styles detection will be noticeably less accurate and it might affect the analysis given.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Disable
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MediaTest;