import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './ThemeProvider';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material/';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
     <CssBaseline/>
   {/* <React.StrictMode> */}
    <App />
   {/* </React.StrictMode> */}
  </ThemeProvider>
);

reportWebVitals();
