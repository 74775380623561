import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { URL, sendEnergyLevel, getStorageMonthDays, getStorageMonths, getStorageSessionCalendar, saveStorageMonthDays, saveStorageMonths, saveStorageSessionCalendar } from "../Helpers";
import { db, doc, getDoc, collection, query, getDocs, getDownloadURL, storage, ref, where } from "../Firebase";
import { Button, Grid, SvgIcon, Typography, Slider } from "@mui/material";
import { useUser } from '../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useErrorBoundary } from "react-error-boundary";
import { chunk } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/system';
import { ReactComponent as ArrowBack } from '../data/arrow_back.svg';
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import LoadingSpinner from "./shared/LoadingSpinner";
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import ProgressBar from '../data/ProgressBar.png';
import Mysession from '../data/Layer_1.png'
import "./CalendarStyle.scss";

const energyColors = {
  5: { background: '#53E394', text: '#2E8354' }, // Good Mood
  4: { background: '#C4F79F', text: '#597445' }, // Above Average Mood 
  3: { background: '#FFFF9E', text: '#9A9A00' }, // Neutral Mood 
  2: { background: '#FFBE8F', text: '#976745' }, // Below Average Mood
  1: { background: '#FF9675', text: '#7F4330' }, // Bad Mood
};

const CustomSlider = styled(Slider)(() => ({
  '& .MuiSlider-rail': {
    // Calculate color based on value
    background: `linear-gradient(to left, green 15%, #ffe000 40%, red 90%)`,
    height: "10px",
    paddingLeft: "10px"
  },
  '& .MuiSlider-track': {
    // Calculate color based on value
    background: `transparent`,
    height: "10px"
  },
  '& .MuiSlider-thumb': {
    // Calculate color based on value
    width: "25px",
    height: "25px"
  },
  '& .MuiSlider-mark': {
    // Calculate color based on value
    backgroundColor: "#5C83BF",
    marginLeft: "5px"
  }
}));

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [showModal, setShowModal] = useState(false);
  const [showVisitModal, setShowVisitModal] = useState(false);
  const [showMoodLevelModal, setShowMoodLevelModal] = useState(false);
  const [sessionNotFound, setSessionNotFound] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [energyLevel, setEnergyLevel] = useState({});
  const [selectedBox, setSelectedBox] = useState(null);
  const [hoveredBox, setHoveredBox] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const [currentView, setCurrentView] = useState('week');
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const monthRef = useRef(null);
  const dayRef = useRef(null);
  // const [moodrepURLs, setMoodrepURLs] = useState([]);
  const [moodrepURLsByDate, setMoodrepURLsByDate] = useState({});
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const daysInMonth = Array.from({ length: currentMonth.daysInMonth() }, (_, i) => i + 1);

  const handleSliderChange = (event, newValue) => {
    setSelectedBox(newValue); // Update selectedBox state with the slider value
  };


  useEffect(() => {
    if (user) {
      // function that uses backend
      const fetch_calendar = async () => {
        const idToken = await user.getIdToken();
        try {
          const response = await fetch(`${URL}/user_calendar`, {
            headers: {
              'Authorization': `Bearer ${idToken}`,
            },
          });
      
          if (response.status === 404) {
            setSessionNotFound(true);
            setLoading(false);
            return;
          } else if (!response.ok) {
            throw new Error(response.statusText);
          }
      
          const docs = await response.json();
      
          // Process energy levels
          const newEnergyLevels = {};
          docs.forEach((element) => {
            if (element.energy_level !== undefined) {
              newEnergyLevels[element.date] = element.energy_level;
            }
          });
          setEnergyLevel(prev => ({ ...prev, ...newEnergyLevels }));
          // Process moodreps and session data
          const moodrepURLsByDate = {};
          const processPromises = docs.map(async (data) => {
            // Store session data in sessionStorage
            if (data.dataframe && data.session_analysis && data.session_dbt) {
              const currentSessionIdentifier = `${user.uid}-${data.date}`;
              const overviewData = {
                energy_level: data.energy_level,
                improved_emotion: data.improved_emotion,
                improved_by: data.improved_by,
                dataframe: data.dataframe
              };
              const analysisData = {
                moodrep: data.moodrep,
                session_analysis: data.session_analysis,
                summary: data.summary,
                analysisimgs: data.analysisimgs
              };
              const dbtData = {
                session_dbt: data.session_dbt,
                dbtimgs: data.dbtimgs
              };
      
              sessionStorage.setItem(`overviewData-${currentSessionIdentifier}`, JSON.stringify(overviewData));
              sessionStorage.setItem(`analysisData-${currentSessionIdentifier}`, JSON.stringify(analysisData));
              sessionStorage.setItem(`dbtData-${currentSessionIdentifier}`, JSON.stringify(dbtData));
            }
      
            // Fetch moodrep URL
            if (data.moodrep) {
              try {
                const moodrepRef = ref(storage, data.moodrep);
                const downloadURL = await getDownloadURL(moodrepRef);
                moodrepURLsByDate[data.date] = downloadURL;
              } catch (error) {
                console.error(`Failed to get download URL for ${data.moodrep}`, error);
              }
            }
          });
      
          await Promise.all(processPromises);
          setMoodrepURLsByDate(moodrepURLsByDate);
      
          setLoading(false);
        } catch (error) {
          showBoundary(error);
          console.error("Error occurred: ", error);
          setLoading(false);
        }
      };

      const fetch_calendar_monthly = async () => {
        const idToken = await user.getIdToken();
        try {
          const month = currentMonth.format("YYYY-MM");
          let storedMonths = getStorageMonths();
      
          if (storedMonths?.includes(month)) {
            // Read from session storage
            const currentMonthDays = getStorageMonthDays(month);
            const moodreps = {};
            const energylevels = {};
      
            for (const day of currentMonthDays || []) {
              const date = currentMonth.date(day).format("YYYY-MM-DD");
              const doc = getStorageSessionCalendar(date);
              console.log('from storage doc', doc);
              if (doc) {
                if (doc.moodrep) moodreps[date] = doc.moodrep;
                if (doc.energy_level !== undefined) energylevels[date] = doc.energy_level;
              }
            }
      
            console.log('from storage', energylevels);
      
            // If energylevels is empty or incomplete, fetch from Firestore
            if (Object.keys(energylevels).length < currentMonthDays?.length) {
              const userID = user?.uid;
              const startOfMonth = currentMonth.startOf('month').toDate();
              const endOfMonth = currentMonth.endOf('month').toDate();
              const sessionQuery = query(
                collection(db, 'users', userID, 'sessions'),
                where('session_time', '>=', startOfMonth),
                where('session_time', '<=', endOfMonth)
              );
      
              const sessions = await getDocs(sessionQuery);
              sessions.forEach(doc => {
                const data = doc.data();
                const date = doc.id;
                if (data.energy_level !== undefined) {
                  energylevels[date] = data.energy_level;
                  // Update session storage
                  const storedDoc = getStorageSessionCalendar(date) || {};
                  storedDoc.energy_level = data.energy_level;
                  saveStorageSessionCalendar(date, storedDoc);
                }
              });
      
              console.log('from Firestore', energylevels);
            }
      
            setEnergyLevel(energylevels);
            setMoodrepURLsByDate(moodreps);
            setLoading(false);
            return;
          }
      
          // Fetch from Firestore and store in session storage
          const startOfMonth = currentMonth.startOf('month').toDate();
          const endOfMonth = currentMonth.endOf('month').toDate();
          const userID = user?.uid;
          const sessionQuery = query(
            collection(db, 'users', userID, 'sessions'),
            where('session_time', '>=', startOfMonth),
            where('session_time', '<=', endOfMonth)
          );
      
          const sessions = await getDocs(sessionQuery);
          const moodrepURLsByDate = {};
          const energy_level_dict = {};
          const daysInMonth = [];
          const imagePromises = [];
      
          sessions.forEach(doc => {
            const data = doc.data();
            const date = doc.id;
            daysInMonth.push(date.split("-")[2]);
      
            // Always check for energy_level
            if (data.energy_level !== undefined) {
              energy_level_dict[date] = data.energy_level;
            }
      
            if (data.moodrep) {
              const moodrepRef = ref(storage, data.moodrep);
              imagePromises.push(
                getDownloadURL(moodrepRef)
                  .then(url => ({ date, url }))
                  .catch(error => {
                    console.error(`Failed to get download URL for ${data.moodrep}`, error);
                    return { date, url: null };
                  })
              );
            }
      
            // Store fetched sessions in session storage
            const currentSessionIdentifier = `${userID}-${date}`;
            const sessionData = {
              energy_level: data.energy_level,
              improved_emotion: data.improved_emotion,
              improved_by: data.improved_by,
              dataframe: data.dataframe,
              moodrep: data.moodrep,
              session_analysis: data.session_analysis,
              summary: data.summary,
              analysisimgs: data.analysisimgs,
              session_dbt: data.session_dbt,
              dbtimgs: data.dbtimgs
            };
      
            // Only store non-null and non-undefined values
            const filteredSessionData = Object.fromEntries(
              Object.entries(sessionData).filter(([_, v]) => v != null)
            );
      
            if (Object.keys(filteredSessionData).length > 0) {
              sessionStorage.setItem(`sessionData-${currentSessionIdentifier}`, JSON.stringify(filteredSessionData));
            }
          });
      
          const moodrepResults = await Promise.all(imagePromises);
          moodrepResults.forEach(({ date, url }) => {
            if (url) {
              moodrepURLsByDate[date] = url;
            }
            const calendarData = {
              moodrep: url,
              energy_level: energy_level_dict[date]
            };
            // Only save non-null values to session storage
            const filteredCalendarData = Object.fromEntries(
              Object.entries(calendarData).filter(([_, v]) => v != null)
            );
            if (Object.keys(filteredCalendarData).length > 0) {
              saveStorageSessionCalendar(date, filteredCalendarData);
            }
          });
      
          setEnergyLevel(energy_level_dict);
          setMoodrepURLsByDate(moodrepURLsByDate);
      
          storedMonths.push(month);
          saveStorageMonths(storedMonths);
          saveStorageMonthDays(month, daysInMonth);
      
          setLoading(false);
        } catch (error) {
          showBoundary(error);
          console.error("Error occurred: ", error);
        }
      };

      // fetch_calendar();
      fetch_calendar_monthly();
      // console.log('called', currentMonth.toString())

    }

    return (() => {
      setEnergyLevel({})
      setMoodrepURLsByDate({})
      setLoading(true)
    })

  }, [user, currentMonth]);

  // const imageGroups = chunk(moodrepURLs, 4);

  const handleDayClick = (day) => {
    const selectedDate = currentMonth.date(day);
    const formattedDate = selectedDate.format("YYYY-MM-DD")
    // console.log(formattedDate, day, dayjs().date(), selectedDate);

    let userID = user?.uid;

    const calendarData = getStorageSessionCalendar(formattedDate)

    if (dayjs(formattedDate).isSame(dayjs(), "day")) {

      if (calendarData?.moodrep) {
        setShowVisitModal(true)
      }
      setSelectedDay(day);
      setShowModal(true);

    }
    else if (dayjs(formattedDate).isBefore(dayjs(), "day")) {

      if (!calendarData) {
        console.log("No session with that date exists");
        return
      }

      if (!calendarData.moodrep) {
        console.log(
          "Mood level already recorded for this day, no session exists"
        );
        return;
      }

      setShowModal(true);
      setShowVisitModal(true);
      setSelectedDay(day);

    }
  };
  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => prevMonth.add(1, "month"));
  };

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => prevMonth.subtract(1, "month"));
  };

  const handleEnergyLevelSelect = useCallback((day, level) => {
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    setEnergyLevel((prevLevels) => ({ ...prevLevels, [date]: level }));
    //get the userID and send energy level to backend

    sendEnergyLevel(user.accessToken, date, level)
    setShowModal(false);
    setShowMoodLevelModal(false);
  }, [currentMonth, user]);


  const getDayColor = useCallback((day) => {
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    return energyColors[energyLevel[date]]?.background || "#FFFFFF";
  }, [energyLevel, currentMonth]);

  const getDayTextColor = useCallback((day) => {
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    return energyColors[energyLevel[date]]?.text || "#4C6481";
  }, [energyLevel, currentMonth]);

  const getBorderColor = useCallback((day) => {
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    return energyColors[energyLevel[date]]?.text  || "#EDE4DF";
  }, [energyLevel, currentMonth]);

  const isCurrentDay = useCallback((day) => {
    const currentDate = dayjs();
    // return currentDate.date() === day && currentDate.month() === currentMonth.month() && currentDate.year() === currentMonth.year();
    return currentDate.isSame(currentMonth.date(day), "day");
  }, [currentMonth]);

  const handleBack = () => {
    setShowMoodLevelModal(false);
  }

  const getMoodRepImage = (day) => {
    // const date = day < 10 ? `${currentMonth.format('YYYY-MM')}-0${day}` : `${currentMonth.format('YYYY-MM')}-${day}`;
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    const url = moodrepURLsByDate[date] || null;

    return url;

  };
  const boxes = Array.from({ length: 5 });

  return (
    <div className="prevent_overflow">
      <Navbar />
      <Grid container alignItems={"center"} justifyContent={"center"} className="content">
        <Grid mb={5} xl={6} lg={7} md={8} sm={11} xs={12}>
          <Grid mb={5} width={"100%"} display={"flex"} alignSelf={"center"} justifyContent={"center"} textAlign={"center"}>
            <Typography variant="h32Bold" color="primary.darkerBlue">
              Mood Tracker
            </Typography>
          </Grid>
          <Grid bgcolor={"#5C83BF"} color={"white"} display={"flex"} borderRadius={"10px 10px 0px 0px"} padding={"16px 24px 16px 24px"} justifyContent={"space-between"}>
            <Button onClick={handlePrevMonth}>
              <FontAwesomeIcon style={{ width: "24px", height: "24px" }} color="white" icon="fa-solid fa-less-than" size="md" />
            </Button>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              classNames="fade"
            >
              <div ref={monthRef}><Typography variant="sb24Bold">{currentMonth.format("MMMM YYYY")}</Typography></div>
            </CSSTransition>
            <Button onClick={handleNextMonth}>
              <FontAwesomeIcon style={{ width: "24px", height: "24px" }} color="white" icon="fa-solid fa-greater-than" size="md" />
            </Button>
          </Grid>
          {loading ? (
            <LoadingSpinner className="smaller_height" />
          ) : (
            <Grid display={"flex"} flexDirection={"column"} bgcolor={"white"} padding={"10px 24px 36px 24px"} gap={"32px"} borderRadius={"0px 0px 10px 10px"} boxShadow={"0px 5px 10px #EFE5DE"}>
              <TransitionGroup className="days-container">
                {daysInMonth.map((day) => (
                  <CSSTransition
                    key={day}
                    timeout={500}
                    classNames="item"
                  >
                    <Grid
                      borderRadius={"8px"}
                      padding={"20px 0px 20px 0px"}
                      border="0.7px solid"
                      boxShadow={"0px 5px 10px rgba(189, 189, 189, 0.18)"}
                      className={`day ${selectedDay === day ? "selected" : ""} ${isCurrentDay(day) ? "current-day" : ""}`}
                      sx={{ backgroundColor: getDayColor(day), color: getDayTextColor(day), borderColor: getBorderColor(day), transition: "background-color 0.5s", }}
                      onClick={() => handleDayClick(day)}
                      ref={dayRef} position={"relative"}
                    >
                      <Grid className="day-number">{day}</Grid>
                      {!isSmallScreen ? (
                        <Grid
                          display={!getMoodRepImage(day) ? "none" : "flex"}
                          position={"absolute"}
                          top={"30px"}
                          left={{ xl: "80px", lg: "60px", md: "60px", sm: "60px", xs: "30px" }}
                          sx={{ borderColor: getBorderColor(day) }}
                          border="1px solid"
                          boxShadow={"0px 5px 10px rgba(0, 0, 0, 0.1)"}
                          width={"40px"}
                          height={"40px"}
                          borderRadius={"100%"}
                          bgcolor={"white"}
                          className="moodrep-image-container"
                        >
                          {getMoodRepImage(day)
                            ? <img key={getMoodRepImage(day)} src={getMoodRepImage(day)} alt={`MoodRep for day ${day}`} width={"50px"} height={"50px"} />
                            : <p className="m-0 d-flex align-items-center justify-content-center h-100"></p>
                          }
                        </Grid>
                      ) : (
                        <Grid className="moodrep-image-container">
                          {getMoodRepImage(day)
                            ? <img key={getMoodRepImage(day)} src={getMoodRepImage(day)} alt={`MoodRep for day ${day}`} width={"60px"} height={"60px"} />
                            : <p className="m-0 d-flex align-items-center justify-content-center h-100"></p>
                          }
                        </Grid>
                      )}
                    </Grid>
                  </CSSTransition>
                ))}
              </TransitionGroup>
              <Grid display={"flex"} flexDirection={"column"} gap={"16px"} textAlign={"center"}>
                <Typography color="primary.fontBlue" variant="sb20Bold">Mood Level Scale</Typography>
                <img width={"95%"} height={isSmallScreen ? "10px" : "15px"} src={ProgressBar} style={{ alignSelf: "center" }} alt="Progress Bar"/>
                <Grid display={"flex"} mx={3} flexDirection={"row"} justifyContent={"space-between"}>
                  <Typography color="otherColors.mainBodyDark" variant="sb16Bold">Lowest</Typography>
                  <Typography color="otherColors.mainBodyDark" variant="sb16Bold">Highest</Typography>
                </Grid>
              </Grid>
              <Grid display={"flex"} alignSelf={"center"}>
                <Button variant="primary" onClick={() => navigate("/session")}>
                  <Typography variant="sb16Bolder">Start New Session</Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Footer />
      <Modal
        show={sessionNotFound}
        onHide={() => setSessionNotFound(false)}
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div style={{ backgroundColor: "rgb(228, 238, 249)", borderRadius: "10px" }}>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">
              No sessions found. Try one?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Typography variant="body1Regular">With Mood Tracker you can access your previous sessions and create a collection. Each day of your session is colored according to the color of your mood level and you can access the details of that day's session by clicking.<span className="fw-bold"><em> Start now to create your own collection!</em></span></Typography>
              <Grid
                display="flex"
                justifyContent="center"
              >
                <Button
                  onClick={() => navigate("/session")}
                  variant="primary"
                >
                  <Typography variant="sb20SemiBold">Start a Session</Typography>
                </Button>
              </Grid>

            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={showModal}
        onHide={() => { setShowModal(false); setShowVisitModal(false) }}
      >
        <Grid borderRadius={"10px"} padding={"0px 0px 36px 0px"} display={"flex"} flexDirection={"column"} gap={"32px"} >
          <Grid bgcolor={"#5C83BF"} padding={"16px 24px 16px 24px"} borderRadius={"6px 6px 0px 0px"}>
            <Typography variant="sb24Bold" color={"white"}>
              Actions for {selectedDay && `${selectedDay}, `}
              {currentMonth.format("MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid>
            {showVisitModal ? (
              <Grid
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  variant="secondary"
                  onClick={() => setShowMoodLevelModal(true)}
                  sx={{ mb: 3 }}
                >
                  <Typography variant="body1Medium"> Change Your Mood Level</Typography>
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    const date = currentMonth.date(selectedDay).format("YYYY-MM-DD");
                    setShowModal(false);
                    navigate(`/overview?selectedDate=${date}`);
                    // setTimeout(() => {
                    //   // window.location.reload();
                    // });
                  }}
                >
                  <Typography variant="body1Medium">Show Session Analysis</Typography>
                </Button>
              </Grid>
            ) : (
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  variant="secondary"
                  onClick={() => setShowMoodLevelModal(true)}
                  sx={{ mb: 3 }}
                >
                  <Typography variant="body1Medium">Record Your Mood Level</Typography>
                </Button>
                <Button
                  variant="primary"
                  onClick={() => navigate("/session")}
                  style={{ display: selectedDay === dayjs().date() ? "block" : "none" }}
                >
                  <Typography variant="body1Medium">Start a Session</Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
      <Modal
        show={showMoodLevelModal}
        onHide={() => setShowMoodLevelModal(false)}
      >
        <Grid bgcolor={"white"} borderRadius={"10px"}>
          <Grid padding={"16px 5px 16px 5px"} display={"flex"}>
            <Button
              onClick={handleBack}
              className="me-3 text-blue"
              sx={{ border: "none", background: "transparent" }}
            >
              <SvgIcon component={ArrowBack} />
            </Button>
            <Typography variant="sb24Bold" color={"#4C6EA2"}>
              Choose your mood level
            </Typography>
          </Grid>
          <Grid padding={"0px 20px 36px 20px"}>
            <Grid display={"flex"} justifyContent={"center"} sx={{ my: 3 }} >
              <CustomSlider aria-label="moodLevel" value={selectedBox} onChange={handleSliderChange} name='moodLevel' min={1} max={5} step="1" marks />
            </Grid>
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Typography variant="body1Regular">Lowest</Typography>
              <Typography variant="body1Regular">Highest</Typography>
            </Grid>
            <Grid display={"flex"} justifyContent={"center"} sx={{ mt: 1 }}>
              <Button
                variant="primary"
                sx={{ width: "50%" }}
                onClick={() => handleEnergyLevelSelect(selectedDay, selectedBox)}
              >
                <Typography variant="body1Medium">Save</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default Calendar;
