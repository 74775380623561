import { Row, Col } from 'react-bootstrap';
import Logo from '../data/front.svg'
import { Link } from "react-router-dom";

const NotFound404 = () => {

  return (
    <div className="text-center prevent_overflow" style={{position: "absolute"}}>
      <Row className="justify-content-center align-items-center text-center my-5">
        <Col xs={12} md={4}>
          <Link to="/">
            <img
              src={Logo}
              alt="pokamind logo"
              style={{maxWidth: '200px'}}
            />
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col className="px-5">
          <h1 className="fw-bold">
            Error <span className="text-blue">404</span>
          </h1>
          <h4>The page you were looking for doesn't exist.</h4>
          <h5>You might have typed in the wrong address or the page has moved. In the meantime, try again or <Link to="/" className="text-blue">return to the home page.</Link></h5>
        </Col>
      </Row>
    </div>
  );
};

export default NotFound404;
