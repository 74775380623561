import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "./Firebase";
import { format } from "date-fns"
import dayjs from "dayjs";

export const getDateString = () => {
  const date = new Date();

  // Retrieve the year, month, and day
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Add 1 since months start from 0
  const day = date.getDate();

  // Add '0' in front of single-digit months and days
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  const dateString = `${year}-${formattedMonth}-${formattedDay}`;
  return dateString;
};

// functions for sessionstorage for calendar 
// Function to save months to sessionStorage
export function saveStorageMonths(months) {
  sessionStorage.setItem("downloadedMonths", JSON.stringify(months));
}

// Function to get months from sessionStorage
export function getStorageMonths() {
  return JSON.parse(sessionStorage.getItem("downloadedMonths")) || [];
}

// Function to save days for a specific month to sessionStorage
export function saveStorageMonthDays(month, days) {
  sessionStorage.setItem(`month-${month}-days`, JSON.stringify(days));
}

// Function to get days for a specific month from sessionStorage
export function getStorageMonthDays(month) {
  return JSON.parse(sessionStorage.getItem(`month-${month}-days`)) || [];
}

export function getStorageSessionCalendar(dateString) {
  return JSON.parse(sessionStorage.getItem(`calendarData-${dateString}`)) || {}
}

export function saveStorageSessionCalendar(dateString, data) {
  sessionStorage.setItem(`calendarData-${dateString}`, JSON.stringify(data));
}
// // Function to save data for a specific day to sessionStorage
// export function saveDayData(date, data) {
//   sessionStorage.setItem(`data-UID-2023-${date}`, JSON.stringify(data));
// }

// // Function to get data for a specific day from sessionStorage
// export function getDayData(date) {
//   return JSON.parse(sessionStorage.getItem(`data-UID-2023-${date}`)) || {};
// }

// http://localhost:4000/api
// /api

export const URL =
  window.location.hostname === "localhost"
    ? "http://localhost:4000/api"
    : "/api";

export const fetchUserRole = async (user) => {
  try {
    let token = await user.getIdTokenResult()
    if (!token.claims.role) {
      console.log('role claim not found, refreshing auth token')
      token = await user.getIdTokenResult(true) //force auth token refresh
    }
    return token.claims.role || "user" //default role
  } catch (error) {
    console.error("Error fetching user role:", error);
    throw error; // Re-throw the error for the caller to handle
  }
};


export const sendEnergyLevel = async (idToken, date, energyLevel) => {
  // Make a fetch request with the ID token in the Authorization header
  const response = await fetch(`${URL}/submit_energy`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ date, energyLevel }),
  });
  const data = await response.json();
  // console.log('energy level response', data)
};

export const submitAnalysisFeedback = async (
  idToken,
  sessionID,
  analysisFeedback,
  analysisFeedbackAdditional
) => {
  try {
    const response = await fetch(`${URL}/submit_analysis_feedback`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID,
        analysisFeedback,
        analysisFeedbackAdditional,
      }),
    });
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const submitSummaryFeedback = async (
  idToken,
  sessionID,
  summaryFeedback,
  summaryFeedbackAdditional
) => {
  try {
    const response = await fetch(`${URL}/submit_summary_feedback`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID,
        summaryFeedback,
        summaryFeedbackAdditional,
      }),
    });
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const submitDBTFeedback = async (
  idToken,
  sessionID,
  dbtFeedback,
  dbtFeedbackAdditional
) => {
  try {
    const response = await fetch(`${URL}/submit_dbt_feedback`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sessionID, dbtFeedback, dbtFeedbackAdditional }),
    });
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const createNewUserDocument = async (accessToken, email) => {
  try {
    const response = await fetch(`${URL}/create_user_document`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

/**
 * Pings the backend if user is logged in to wake up and reduce cold boot times.
 *
 * @param {string} accessToken - The access token for authentication.
 * @returns {boolean} Returns true if the backend is successfully pinged.
 * @throws {Error} Throws an error if an issue occurs during the ping.
 */
export const pingBackend = async (accessToken) => {
  try {
    const response = await fetch(`${URL}/ping`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    console.log("backend pinged");
    return true;
  } catch (error) {
    console.error("An error occurred:", error);
    throw new Error(`Error: ${error}`);
  }
};
