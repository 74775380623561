import React from 'react';
import { Typography, Box } from '@mui/material';
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import { useUser } from '../contexts/UserContext';

const Plans = () => {
  const { user } = useUser();

  return (
    <div className="prevent_overflow">
      <Navbar />
      <div className="content">
        <Typography variant="h2" align="center" gutterBottom>
          Pricing Plans
        </Typography>
        <stripe-pricing-table
          pricing-table-id={String(process.env.REACT_APP_StripePricingTableId)}
          publishable-key={String(process.env.REACT_APP_stripeKey)}
          pricing-table-options='{"pricing_table_data":{"pricing_table_mode":"horizontal"}}'
          client-reference-id={user.uid}
          customer-email={user.email}
        >
        </stripe-pricing-table>
        <Box mt={4} textAlign="center">
          <Typography variant="h6" mx={4} >
            Special Offer for Palestinian Users
            If you are Palestinian, we offer free premium access and mental health support. 
            <Typography variant="h6">Simply contact us at team@pokamind.com 🇵🇸</Typography>     
          </Typography>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default Plans;