import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useUser } from './contexts/UserContext';

//checks if user is logged in and authenticated
const ProtectedAuthRoute = (props) => {
    const { user, role } = useUser();
    const location = useLocation();
    // console.log('user:', user);
    // console.log('user role:', role);
    if (user) {
        return <Outlet />;
    } else {
        //added state: from location to allow going back to previous page the user was at
        return <Navigate to="/auth" state={{ from: location }} replace />;
    }
};

export default ProtectedAuthRoute;