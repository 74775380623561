import React from "react";
import { useLocation } from 'react-router-dom';
import { Grid, Typography } from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import "./style.scss";
import { useUser } from "../contexts/UserContext";

const WellbeingReport = () => {
  const location = useLocation();
  const { adminReport } = location.state || {};
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const { user, role, tier, premium_status, stripe_customer_id } = useUser();

  return (
    <div className="prevent_overflow">
      <Navbar />
      <Grid display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h32Medium" color={"#4C6481"} mb={4}>Wellbeing Report</Typography>
        <Grid container justifyContent={"center"} gap={"40px"}>
          <Grid item xs={12} sm={11} md={10} lg={8} bgcolor={"white"} borderRadius={"10px"} border={"1px solid #E3DBD5"}>
            <Grid bgcolor={"#5C83BF"} padding={"16px 24px"} borderRadius={"10px 10px 0px 0px"}>
              <Typography variant="sb24Bold" color={"white"}>ESG Wellbeing Analysis</Typography>
            </Grid>
            <Grid bgcolor={"white"} padding={isSmallScreen ? "20px" : "42px"} borderRadius={"0px 0px 10px 10px"}>
              {adminReport ? (
                <div dangerouslySetInnerHTML={{ __html: adminReport }} />
              ) : (
                <Typography variant="body1">No report available</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default WellbeingReport;