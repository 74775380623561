import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { renderToString } from "react-dom/server";

const EnergyAreaChart = ({ levelsByDates, startDate, endDate, daysBetweenSessions }) => {
  const entries = Object.entries(levelsByDates).map(([date, energy]) => ({ date, energy }));

  const filteredEntries = entries.filter(({ date }) => new Date(date) >= startDate && new Date(date) <= endDate);

  filteredEntries.sort((a, b) => new Date(a.date) - new Date(b.date));

  const averageEntries = filteredEntries.reduce((acc, { date, energy }) => {
    const dateKey = new Date(date).toDateString(); // Normalize the date to ignore time
    if (!acc[dateKey]) {
      acc[dateKey] = { date: dateKey, totalEnergy: 0, count: 0 };
    }
    acc[dateKey].totalEnergy += energy;
    acc[dateKey].count += 1;
    return acc;
  }, {});

  const averagedEntries = Object.values(averageEntries).map(({ date, totalEnergy, count }) => ({
    date,
    energy: totalEnergy / count,
  }));

  averagedEntries.sort((a, b) => new Date(a.date) - new Date(b.date));

  const dates = averagedEntries.map(({ date }) => date);
  const levels = averagedEntries.map(({ energy }) => energy);

  const timestamps = Array.from({ length: levels.length }, (_, index) => index + 1);
const proportionalDays = timestamps.map((timestamp) =>
    Math.round((timestamp / (levels.length + 1)) * daysBetweenSessions)
);

const gradientColors = ['rgba(59, 135, 67, 0.13)' ,'rgba(59, 135, 67, 0.1)'];
const gradientStops = [0, 99, 100];

  const chartData = [
    {
      name: "Energy Levels",
      data: levels.map((energy, index) => ({
        x: proportionalDays[index],
        y: energy,
      })),
    },
  ];

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: true,
        offsetY: -35,
        offsetX: 10,
        tools: {
          download: `<div>${renderToString(<FontAwesomeIcon size='lg' icon={faDownload} />)}</div>`,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "numeric",
      labels: {
        formatter: function (val) {
          const dateIndex = Math.min(Math.round((val / daysBetweenSessions) * levels.length), levels.length - 1);
          const formattedDate = new Date(dates[dateIndex]).toLocaleDateString("en-GB");
          return formattedDate;
        },
      },
    },
    yaxis: {
      opposite: true,
      min: 0,
    },
    legend: {
      horizontalAlign: "left",
    },
    colors: ['rgba(59, 135, 67, 0.8)'],
        fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: gradientColors,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: gradientStops,
      }
    }
  };

  return (
    <div>
      <div id="energy-chart">
        <ReactApexChart options={chartOptions} series={chartData} type="area" height={350} />
      </div>
    </div>
  );
};

export default EnergyAreaChart;
