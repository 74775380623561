import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from './contexts/UserContext';

const ProtectedAdminRoute = (props) => {
  const { role } = useUser();
  const location = useLocation();
  if (role === 'admin') {
    return <Outlet />;
  } else {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
};

export default ProtectedAdminRoute;
