import { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [overviewData, setOverviewData] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);
  const [dbtData, setDbtData] = useState(null);
  const [sessionContextDate, setSessionContextDate] = useState(null); //save the date for the saved session

  return (
    <SessionContext.Provider 
      value={{ 
        overviewData, 
        setOverviewData,
        analysisData, 
        setAnalysisData,
        dbtData, 
        setDbtData,
        sessionContextDate,
        setSessionContextDate
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};