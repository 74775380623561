import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import './style.scss';

const OnBoardingPendingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/my-profile');
    }, 15000);

    // Cleanup function in case the component unmounts before the timer has finished
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="prevent_overflow">
      <Navbar />
      <div className="success-page d-flex flex-column align-items-center justify-content-center content">
        <h1 className="text-warning fw-bold">Onboarding Incomplete</h1>
        <h4 className="text-center">
          Your onboarding process is not complete yet, please complete the onboarding process to be visible for PokaMind users.
        </h4>
        <h5 className="text-center">
          You will be redirected to your profile page in 15 seconds.
        </h5>
      </div>
      <Footer />
    </div>
  );
};

export default OnBoardingPendingPage;