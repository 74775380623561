import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import { useErrorBoundary } from "react-error-boundary";
import { useUser } from '../contexts/UserContext';
import { Modal, Button } from 'react-bootstrap';
import { URL } from '../Helpers';
import LoadingSpinner from './shared/LoadingSpinner';
import './style.scss';


const verifyingMessage = "Please wait while your payment is verified"

const SubscriptionSuccess = () => {
    const [sessionDetails, setSessionDetails] = useState(null);


    const { session_id } = useParams();
    const { showBoundary } = useErrorBoundary();
    const { user, refreshUserClaims } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState("verifying")
    const [subscriptionStatus, setSubscriptionStatus] = useState(null)

    useEffect(() => {
        // user.getIdTokenResult().then((token) => console.log(token))

        // Function to fetch session details from the server
        const verifySuccess = async (session_id) => {
            setLoading(true)
            // Call backend to book appointment and fetch checkout session details
            try {
                const response = await fetch(`${URL}/verify_premium_success/${session_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.accessToken}`
                    },
                    // body: JSON.stringify({ payment_period })
                });

                if (response.ok) {
                    const responseData = await response.json();
                    // console.log({ responseData })
                    //refresh user claims to set new tier
                    // console.log(responseData)
                    refreshUserClaims(user)
                    setLoading(false)
                    setSessionDetails(responseData.session)
                    setSubscriptionStatus(responseData.subscription_status)
                    setStatus("verified")

                } else {
                    const errorMessage = await response.text();
                    // showBoundary(new Error(`Could not subscribe appointment: ${errorMessage}`));
                    console.error(errorMessage)
                    setStatus("error")
                }
            } catch (error) {
                showBoundary(new Error('Error fetching session details: ' + error));
            }
        };

        if (user && session_id ) {
            verifySuccess(session_id);
        } else if (user && !session_id ) {
            showBoundary(new Error("no session ID or plan provided in the URL"));
        }
    }, [user, session_id, showBoundary]);

    useEffect(() => {
        if (sessionDetails && status !== "verifying") {

            setTimeout(() => {
                navigate('/get-started', { replace: true })
            }, 15000);
        }
    }, [sessionDetails, navigate, status]);


    // if (!sessionDetails) {
    //     return (
    //         <div>
    //             <LoadingSpinner className="smaller_height" />
    //         </div>
    //     )
    // }

    //still waiting for verification response from backend
    if (status === "verifying")
        return (
            <>
                <Navbar />
                <div className="prevent_overflow justify-content-center">
                    <LoadingSpinner text={verifyingMessage}></LoadingSpinner>
                </div>
            </>
        )

    //verification returned error
    if (status === "error")
        return (
            <div className="prevent_overflow">
                <Navbar />
                <div className="d-flex flex-column align-items-center justify-content-center content">
                    {/* <FailureIcon className="failure-icon mb-4" />  */}
                    <h1 className="text-danger fw-bold">Payment Failed</h1>
                    <h4 className="text-center">
                        There was a problem processing your payment. No charge has been made. Please try again or contact support.
                    </h4>
                    <h5 className="text-center">
                        You will be redirected back to the home page shortly or you can <Link to="/get-started" className="text-blue">click here</Link> to go directly to the home page.
                    </h5>
                </div>
                <Footer />
            </div>)

    //status payment succeeded
    return (
        <div className="prevent_overflow">
            <Navbar />
            <div className="success-page d-flex flex-column align-items-center justify-content-center content">
                <h1>Subscription Status: </h1><h1 className={`fw-bold ${subscriptionStatus === 'active' ? 'text-success' : 'text-danger'}`}>{subscriptionStatus}</h1>
                <h4 className="text-center">
                    You can go to the <strong><a href={`${process.env.REACT_APP_StripeCustomerPortal}?prefilled_email=${encodeURIComponent(user.email)}`} target="_blank" rel="noopener noreferrer">Customer Portal</a></strong> to see more details about your subscription or Check your email for the details. If you do not see the Premium subscription in your <Link to="/user-profile" className="text-blue fw-bold">User Profile</Link> please try logging out and logging in again
                </h4>
                <h5 className="text-center">
                    You will be redirected to the home page shortly or you can <Link to="/get-started" className="text-blue">click here</Link> to go directly to the home page.
                </h5>
            </div>
            <Footer />
        </div>
    );
};

export default SubscriptionSuccess;