import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import { renderToString } from 'react-dom/server';
import "./AdminView.scss"
import { styled } from '@mui/system';

const StyledChart = styled(Chart)(({ theme }) => ({
  '& .apexcharts-bar-area:nth-child(1)': {  // Target the first bar
    fill: colorsMap['first'],  // Fill color for the first bar
    stroke: "#689C64", // Border color for the first bar
    strokeWidth: 1, // Border width
    width:"50px"
  },
  '& .apexcharts-bar-area:nth-child(2)': {  // Target the second bar
    fill: colorsMap['second'],  // Fill color for the second bar
    stroke: "#9E9567", // Border color for the first bar
    strokeWidth: 1, // Border width
  },
  '& .apexcharts-bar-area:nth-child(3)': {  // Target the third bar
    fill: colorsMap['third'],  // Fill color for the third bar
    stroke: "#7F4A89", // Border color for the first bar
    strokeWidth: 1, // Border width
  },
  '& .apexcharts-bar-area:nth-child(4)': {  // Target the third bar
    fill: colorsMap['fourth'],  // Fill color for the third bar
    stroke: "#7792d8", // Border color for the first bar
    strokeWidth: 1, // Border width
  },
  '& .apexcharts-bar-area:nth-child(5)': {  // Target the third bar
    fill: colorsMap['fifth'],  // Fill color for the third bar
    stroke: "#d87777", // Border color for the first bar
    strokeWidth: 1, // Border width
  },
  '& .apexcharts-yaxis-label ' : {
    fontSize: '14px',  // Font size
    fontWeight: 700,    // Font weight
    fill: '#4C6EA2',   // Text color
    fontFamily: 'Nunito !important'
  }
}));

const colorsMap = {
  first: '#81D073',
  second: '#D8C677',
  third: '#A54FAD',
  fourth: '#7792d8',
  fifth: '#d87777'
};

const EmotionBarChart = ({ emotionCountsChartData }) => {
  const { categories, emotionCounts } = emotionCountsChartData || {
    categories: [],
    emotionCounts: [],
  };

  const topFiveEmotionCounts = emotionCountsChartData.slice(0, 5);

  const [chartWidth, setChartWidth] = useState(400);

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(
        window.innerWidth <= 678
          ? window.innerWidth <= 500
            ? window.innerWidth <= 420
              ? 260
              : 320
            : 410
          : 400
      );
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const series = [
    {
      name: "Communication Styles Count",
      data: topFiveEmotionCounts.map((emotion) => emotion.count),
    },
  ];

const options = {
  chart: {
    type: "bar",
    height: 220,
    toolbar: {
      show: true,
      offsetY: -35, // Adjust this value to change the vertical position of the toolbar
      offsetX: window.innerWidth <= 700 ? 20 : 80, // Update offsetX based on screen size
      tools: {
        download: `<div>${renderToString(<FontAwesomeIcon size='lg' icon={faDownload} />)}</div>`,
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 3,
      barHeight: 45,
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val;
    },
    offsetX: 0,
    style: {
      fontSize: '14px',
      colors: ["white"],
    },
  },
  stroke: {
    show: true,
    width: 1,
  },
  xaxis: {
    categories: topFiveEmotionCounts.map((emotion) => emotion.emotion),
    labels: {
      show: false, // Set this to false to hide labels under x-axis
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 0.2,
      opacityFrom: 0.3,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
};

  return (
    <div>
      <StyledChart options={options} series={series} type="bar" height={320} width={chartWidth} />
    </div>
  );
};

export default EmotionBarChart;
